import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { map, size } from 'lodash';
import classNames from 'classnames';
import ScrollArea from 'react-scrollbar';
import i18n from 'i18next';

import {
  Row,
  Col,
  Button,
  Media,
} from 'reactstrap';

import {
  getAchievements,
  claimAchievement,
} from '../../../../../actions/member';

import {
  ACHIEVEMENT_IDS,
  UNIQUE_ACHIEVEMENT_DATA,
  ACHIEVEMENT_DATA,
  GAMESPLAYED,
  GAMESWON,
  BIG_SIZE_ACH
} from '../../../../../constants/constants';
import coinImg from '../../../../../images/redesign/common/coin.svg';

const AchievementsModal = ({}) => {
  const { t } = useTranslation('achievements');
  const dispatch = useDispatch();

  const userAchievements = useSelector(state => state.member.userAchievements || {});
  const claimedAchievements = useSelector(state => state.member.claimedAchievements || {});
  const gamesPlayed = useSelector(state => state.member.gamesPlayed || 0);
  const gamesWon = useSelector(state => state.member.gamesWon || 0); 
  const screenMode = useSelector(state => state.member.screenMode);
  useEffect(() => {
    dispatch(getAchievements());
  }, []);

  const showUnlockAchievements = (key, userAchieve) => {
    if (size(UNIQUE_ACHIEVEMENT_DATA[key]) > 0) {
      let name = '';
      let reward = 0;

      name = t(UNIQUE_ACHIEVEMENT_DATA[key].label);
      if (userAchieve && !claimedAchievements[key]){
        reward = UNIQUE_ACHIEVEMENT_DATA[key].reward;
      }

      let img;

      try {
        // eslint-disable-next-line
        if (BIG_SIZE_ACH.includes(key)) {
          img = require(`../../../../../images/Ach/${key}_1.svg`);
        } else {
          img = require(`../../../../../images/Ach/${key}_1.png`);
        }
      } catch (e) {
        console.log(e);
      }

      if (reward > 0) {
        return (
          <div className="my-info-achievements-column mb-4" key={key}>
            <div className="my-info-achievements-column-div">
              <div className="achievement-img-bg" />
              <Media src={img} className="my-info-achievements-column-img signle-achievement-img" />
            </div>
            <div className="my-info-achievements-column-info mt-2 mt-3">
              <span>{`${name}!`}</span>
              <p className="d-flex align-items-center">
                {t('achieveEarnInfo3')}
                <Media className="ml-1 mr-1" src={coinImg} alt="x" />
                <span>{reward}</span>
                <span className="ml-1">{t('achieveEarnInfo2')}</span>
              </p>
            </div>
          </div>
        );
      }
    } else if (size(ACHIEVEMENT_DATA[key]) > 0) {

      const translationKeys = ACHIEVEMENT_DATA[key]?.translationKeys[i18n.language] || ['404'];

      let borders = [];
      borders = ACHIEVEMENT_DATA[key].borders;
      let rewards = [];
      rewards = ACHIEVEMENT_DATA[key].rewards;
      const rev = ACHIEVEMENT_DATA[key]?.rev || false;

      return (
        borders.map((item, index) => {
          // if element for achievement index doesnt exist, use last element
          const name = translationKeys[index] || translationKeys[translationKeys.length - 1];

          let img;

          //  if (item <= userAchievements && claimedAchievementsVal <= index) {
          //    reward = reward + rewards[index];
          //  }

          let imgKey = key;
          if (key === 'highestEarned') {
            imgKey = 'balance';
          }

          try {
            if (borders[index + 1]) {
              // eslint-disable-next-line
              img = require(`../../../../../images/Ach/${imgKey}_${borders[index]}_${borders[index + 1]}.png`);
            } else {
              // eslint-disable-next-line
              img = require(`../../../../../images/Ach/${imgKey}_${borders[index]}.png`);
            }
          } catch (e) {
            console.log(e);
          }
          const val = borders[index] >= 1000 ? `${borders[index] / 1000}k` : borders[index];
          const claimAchieve = claimedAchievements[key] || 0;

          if (userAchieve >= borders[index] && claimAchieve < index + 1) {
            const hideNumber = (ACHIEVEMENT_DATA[key].dontShowValue === 'first' && index === 0) || ACHIEVEMENT_DATA[key].dontShowValue === 'all';
            const placeNumberAfterText = ACHIEVEMENT_DATA[key].numPlacement === 'after';
            return (
              // eslint-disable-next-line
              <div className="my-info-achievements-column mb-4" key={key + index}>
                <div className="my-info-achievements-column-div">
                  <div className="achievement-img-bg" />
                  <Media src={img} className="my-info-achievements-column-img" />
                </div>
                <div className="my-info-achievements-column-info mt-2 mt-3">
                  <span>{`${hideNumber || placeNumberAfterText ? '' : val} ${t(name)}${placeNumberAfterText ? ` ${val}` : ''}!`}</span>
                  <p className="d-flex align-items-center">
                    {t('achieveEarnInfo3')}
                    <Media className="ml-1 mr-1" src={coinImg} alt="x" />
                    <span>{rewards[index]}</span>
                    <span className='ml-1'>{t('achieveEarnInfo2')}</span>
                  </p>
                </div>
              </div>
            );
          }
        })
      );
    }
  }

  let userAchievementsAll = {};
  if (size(userAchievements) > 0) {
    userAchievementsAll = {
      ...userAchievements,
      gamesPlayed,
      gamesWon,
    }
  } else {
    userAchievementsAll = {
      gamesPlayed,
      gamesWon,
    }
  }

  return (
    <div className="achievements-unlock-modal">
      <div className="d-flex align-items-center flex-column achievements-unlock-modal-content">
        <p className="subheader">{t('congratulations')}</p>
        <p>{t('unlockContent')}</p>
      </div>
      <ScrollArea
        speed={0.8}
        className={`achievements-unlock-modal-scrollarea achievements-unlock-modal-scrollarea-${screenMode}`}
        contentClassName={`achievements-unlock-modal-scrollarea-section achievements-unlock-modal-scrollarea-${screenMode}-section`}
        smoothScrolling
        horizontal={true}
        horizontalContainerStyle={{
          background: 'transparent',
          opacity: 1,
          width: 10,
          height: 15,
          marginRight: 2,
        }}
        horizontalScrollbarStyle={{
          background: '#427C78 !important',
          borderRadius: 1,
          width: 10,
          height: 15,
          marginTop: '6px',
        }}
        vertical={false}
        minScrollSize={25}
      >
        {
          userAchievementsAll && claimedAchievements && map(userAchievementsAll, (item, key) => (
            showUnlockAchievements(key, item)
          ))
        }
      </ScrollArea>
    </div>
  );
};

export default AchievementsModal;
