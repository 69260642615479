export const countDigits = number => {
    const numberString = number.toString();
    const numberOfDigits = numberString.length;
    return numberOfDigits;
};

export const getRandomNumber = (min, max) => {
    const randomDecimal = Math.random();
    const randomInRange = min + randomDecimal * (max - min);
    const finalResult = Math.floor(randomInRange);
    return finalResult;
};