import React from 'react';
import PropTypes from 'prop-types';

import Media from 'reactstrap/lib/Media';
// import Button from 'reactstrap/lib/Button';

import coinImg from '../../../../../../images/redesign/common/coin.svg';

class Gift extends React.Component {
  static propTypes = {
    //  t: PropTypes.func.isRequired,
  //  giftId: PropTypes.string,
    id: PropTypes.string,
    gift: PropTypes.shape(),
    isSelected: PropTypes.bool,
    selectGift: PropTypes.func.isRequired,
    altClassName: PropTypes.string,
    isDrinkGift: PropTypes.bool,
  };

  static defaultProps = {
  //  giftId: null,
    id: null,
    gift: {},
    isSelected: false,
    altClassName: null,
    isDrinkGift: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

/*  shouldComponentUpdate(nextProps) {
    const {
      giftId,
      id,
      //  gift,
      isFullscreen,
    } = nextProps;
    const {
      giftId: curGiftId,
      id: curId,
      isFullscreen: curIsFullscreen,
      //  gift: curGift,
    } = this.props;

    if (giftId !== curGiftId && giftId === curId) {
      return true;
    }

    if (giftId !== curGiftId && curGiftId === curId) {
      return true;
    }

    if (id !== curId) {
      return true;
    }

    if (isFullscreen !== curIsFullscreen) {
      return true;
    }

    //  if (gift !== curGift) {
    //    return true;
    //  }

    return false;
  } */

  selectGift = () => {
    const { selectGift, id } = this.props;

    selectGift(id);
  }

  render() {
    const {
      //  t,
    //  giftId,
      id,
      gift,
    //  selectGift,
    //  isFullscreen,
    //  curGiftId,
      isSelected,
      altClassName,
      isDrinkGift,
    } = this.props;


    return (
      <div
        key={id}
        className={`gifts-gift ${isSelected ? 'gifts-gift-selected' : ''} ${altClassName ? altClassName : ''}`}
        onClick={this.selectGift}
        style={{ cursor: 'pointer' }}
      >
        <Media src={gift.image} className="gifts-gift-image" />
        {isDrinkGift &&
          <div className="gifts-gift-pricebox-new">
            <Media src={coinImg} />
            {gift.price}
          </div>
        }

      </div>
    );
  }
}

export default Gift;
