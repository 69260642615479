import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { getRandomNumber } from '../../../../../utils/numUtils';

const getCardMoveClasses = (number, card) => {
  if (number === 0) {
    return [`${card}-card-drunk-move-up`, `${card}-card-drunk-move-down`]
  }
  else if (number === 1) {
    return [`${card}-card-drunk-move-left`, `${card}-card-drunk-move-right`]
  }
  else if (number === 2) {
    return [`${card}-card-drunk-move-down`, `${card}-card-drunk-move-up`]
  }
  else if (number === 3) {
    return [`${card}-card-drunk-move-right`, `${card}-card-drunk-move-left`]
  }
  else if (number === 4) {
    return [`${card}-card-drunk-move-up-2`, `${card}-card-drunk-move-down-2`]
  }
  else if (number === 5) {
    return [`${card}-card-drunk-move-left-2`, `${card}-card-drunk-move-right-2`]
  }
  else if (number === 6) {
    return [`${card}-card-drunk-move-down-2`, `${card}-card-drunk-move-up-2`]
  }
  else if (number === 7) {
    return [`${card}-card-drunk-move-right-2`, `${card}-card-drunk-move-left-2`]
  }
};

const Render3PlayerCards = React.memo(({
  currentTable, cardsAdded, myPos, currentTurn, tableIsInProgress, isDrunk, gameState, firstToGo, // isFullscreen, isWebVersion,
}) => {
  const items = [];

  const [cardMoveClassesMiddle, setCardMoveClassesMiddle] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'middle'));
  const [cardMoveClassesLeft, setCardMoveClassesLeft] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'left'));
  const [cardMoveClassesRight, setCardMoveClassesRight] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'right'));

  if (myPos === 'player3') {

    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (isDrunk) {
        if (currentTable[key] && currentTable[key].player === 'player1') {
          //  p1 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesLeft[1]} table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player2') {
        //  p2 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesRight[1]} table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player3') {
        //  p3 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
      }
      return null;
    });

    // table card backgrounds
    if (currentTable && currentTable.length <= 3) {
      if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
        items.push(<div className="table-card-bg card-0" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
      } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
        items.push(<div className="table-card-bg card-2" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
      } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
        items.push(<div className="table-card-bg card-1" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
      }
    }

    return (
      <div className={`table-cards ${isDrunk && 'pulse-blur'}`}>
        {items}
      </div>
    );
  }

  if (myPos === 'player1') {
    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (isDrunk) {
        if (currentTable[key] && currentTable[key].player === 'player1') {
          //  p1 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player2') {
          //  p2 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesLeft[1]} table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player3') {
          //  p3 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesRight[1]} table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
      }

      return null;
    });

    // table card backgrounds
    if (currentTable && currentTable.length <= 3) {
      if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
        items.push(<div className="table-card-bg card-1" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
      } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
        items.push(<div className="table-card-bg card-0" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
      } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
        items.push(<div className="table-card-bg card-2" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
      }
    }

    return (
      <div className={`table-cards ${isDrunk && 'pulse-blur'}`}>
        {items}
      </div>
    );
  }

  if (myPos === 'player2') {
    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} />);
      }

      if (isDrunk) {
        if (currentTable[key] && currentTable[key].player === 'player1') {
          //  p1 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesRight[1]} table-card card card-2 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player2') {
          //  p2 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player3') {
          //  p3 = true;
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesLeft[1]} table-card card card-0 card-order-${index} table-card-${currentTable[key].card}`} style={{opacity: 0.5}} />);
        }
      }
      return null;
    });

    // table card backgrounds
    if (currentTable && currentTable.length <= 3) {
      if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
        items.push(<div className="table-card-bg card-2" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
      } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
        items.push(<div className="table-card-bg card-1" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-0" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
      } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
        items.push(<div className="table-card-bg card-0" />);
        if (currentTable.length < 2) {
          items.push(<div className="table-card table-card-bg-after card-2" />);
        }
        if (currentTable.length < 1) {
          items.push(<div className="table-card table-card-bg-after card-1" />);
        }
      }
    }

    return (
      <div className={`table-cards ${isDrunk && 'pulse-blur'}`}>
        {items}
      </div>
    );
  }

  return null;
});

Render3PlayerCards.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  isFullscreen: PropTypes.bool,
  isWebVersion: PropTypes.bool,
  isDrunk: PropTypes.number,
};

Render3PlayerCards.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  isFullscreen: false,
  isWebVersion: false,
};

export default Render3PlayerCards;
