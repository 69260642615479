import React, { Fragment } from 'react';
import { Button, Media } from 'reactstrap';
import SecondTimer from '../Redesign/Components/Components/SecondTimer';
import coinImg from '../../../images/coin.svg';

const SnackMessageOld = ({wineGiftTimer, wineGiftSender, wineGiftMessageVisible, setWineGiftSenderFunc, setWineGiftMessageVisibleFunc, wineGiftRemoveFunc, setWineGiftTimerDisabledFunc, t}) => {
    const countdown = <SecondTimer finishTimeStamp={wineGiftTimer} onFinished={() => { setWineGiftMessageVisibleFunc(false); setWineGiftSenderFunc(null); setWineGiftTimerDisabledFunc(); }}/>
    return (
        <Fragment>
            <div className="snack-message-old-textbox" style={{visibility: wineGiftMessageVisible ? 'visible': 'collapse'}}>
                <p className="mb-1"><b>{wineGiftSender}</b> {t('notifications:serveDrink')}</p>
                <p className="mb-1">{t('notifications:fogTime')} <b>{countdown}</b> {t('notifications:fogTimeSeconds')}</p>
                <p className="mb-1">{t('notifications:takeSnack')}</p>
                <p className="mb-1">{t('notifications:snackInfo')}</p>

                <Button
                    type="button"
                    color="link"
                    className="snack-message-old-textbox-button snack-message-old-textbox-button-snack"
                    onClick={() => wineGiftRemoveFunc()}
                >
                    <Media className="snack-message-old-textbox-button-coins-image" src={coinImg} />
                    <span className="snack-message-old-textbox-button-amount">100</span>
                    {t('notifications:snack')}
                </Button>
                <Button
                    type="button"
                    color="link"
                    className="snack-message-old-textbox-button snack-message-old-textbox-button-close"
                    onClick={() => setWineGiftMessageVisibleFunc(false)}
                >
                    {t('notifications:close')}
                </Button>
            </div>
        </Fragment>
    );
};

export default SnackMessageOld;