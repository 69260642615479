import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import CircularProgressbar from '../../Components/CircularProgressbar/ProgressBarTest';

import {
  roomDataFallbackNew,
} from '../../../../../actions/game';

import {
  getTimeOffset,
} from '../../../../../actions/member';

import defaultImage from '../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../images/redesign/light-mode/common/default_image.svg';
import { SCREEN_MODE } from '../../../../../constants/constants';

class TurnTimer extends React.Component {
  static propTypes = {
    nextTimeStamp: PropTypes.number,
    endRoom: PropTypes.func.isRequired,
    closeResultModal: PropTypes.func.isRequired,
    offset: PropTypes.number,
    gameResultModalShown: PropTypes.bool,
    disableTimer: PropTypes.bool,
    fastGame: PropTypes.bool,
    lightningGame: PropTypes.bool,
    unlimitedGame: PropTypes.bool,
    roomClosed: PropTypes.bool,
    gameState: PropTypes.string,
    speed: PropTypes.number,
    isRunning: PropTypes.bool,
    roomId: PropTypes.string,
    currentTurn: PropTypes.string,
    myPos: PropTypes.string,
    showEndResultModal: PropTypes.bool,
    getOffset: PropTypes.func.isRequired,
    fetchRoomDataFallback: PropTypes.func.isRequired,
    screenMode: PropTypes.string,
    mWidth: PropTypes.number,
  };

  static defaultProps = {
    nextTimeStamp: null,
    offset: 0,
    gameResultModalShown: false,
    disableTimer: null,
    fastGame: null,
    lightningGame: null,
    unlimitedGame: null,
    //  lightningGame: null,
    roomClosed: false,
    gameState: null,
    speed: 25,
    isRunning: null,
    roomId: null,
    currentTurn: null,
    myPos: null,
    showEndResultModal: false,
    mWidth: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      percentage: 100,
      timer: '',
      closeResultModalCalled: false,
      endRoomCalled: false,
    };
  }

  componentDidMount() {
    const { lightningGame, unlimitedGame, fastGame } = this.props;

    let nextTimerTime;

    let resultsSpeed = 6;

    if (lightningGame) {
      resultsSpeed = 2;
    } else if (fastGame) {
      resultsSpeed = 3;
    } else if (unlimitedGame) {
      resultsSpeed = 7;
    }

    if (this.props.isRunning) {
      if (this.props.nextTimeStamp && this.props.nextTimeStamp !== null && (this.props.offset || this.props.offset === 0)) {
        nextTimerTime = ((this.props.nextTimeStamp - (Date.now() + this.props.offset - 500)) / 1000);

     //   console.log('nextTimerTime', nextTimerTime, resultsSpeed);

        this.setState({
          percentage: (nextTimerTime / (this.props.gameState === 'results' ? resultsSpeed : this.props.speed)) * 100,
          timer: Math.ceil(nextTimerTime),
        });
      } else {
        this.setState({
          timer: 0,
        });
      }
    }

    this.intervalID = setInterval(() => {
      const {
        nextTimeStamp, offset, gameState, isRunning, speed,
      } = this.props;

      if (isRunning) {
        if (nextTimeStamp && nextTimeStamp !== null && (offset || offset === 0) && isRunning) {
          nextTimerTime = ((nextTimeStamp - (Date.now() + offset - 500)) / 1000);

          
          this.setState({
            percentage: (nextTimerTime / (gameState === 'results' ? resultsSpeed : speed)) * 100,
            timer: Math.ceil(nextTimerTime),
          });
        } else {
          this.setState({
            timer: 0,
          });
        }
      }
    }, 300);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { timer } = this.state;
    const nextTimer = nextState.timer;

    if ((timer || timer === 0) && (nextTimer || nextTimer === 0) && timer !== nextTimer) {
      return true;
    }

    if (this.props.notActive !== nextProps.notActive) {
      this.setState({
        percentage: 100
      })
      return true;
    }

    if (this.props.gameState !== nextProps.gameState) {
      return true;
    }

    if (this.props.currentTurn !== nextProps.currentTurn) {
      return true;
    }

    if (this.props.nextTimeStamp !== nextProps.nextTimeStamp) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { timer } = this.state;
    const prevTimer = prevState.timer;
    
    if (timer !== prevTimer) {
      const {
        roomClosed,
        gameState,
        nextTimeStamp,
        gameResultModalShown,
        disableTimer,
        currentTurn,
        myPos,
        fastGame,
        isRunning,
        speed,
        getOffset,
        showEndResultModal,
      } = this.props;

      let refetchLimit = Math.round((speed / 2));

      if (speed > 20) {
        refetchLimit = Math.round((speed / 3));
      }

      if (speed + 1 < timer) {
        getOffset();
      }

      if (isRunning && currentTurn === null && ((timer === refetchLimit - 3) || timer === (refetchLimit + 3))) {
      //  this.fetchRoomDataFallback(true);
      }
      

      if (gameState === 'results') {
        if (currentTurn !== myPos && (timer === -4)) {
        //  this.fetchRoomDataFallback(false);
        }
        
        if (timer === -1) {
        //  this.fetchRoomDataFallback(false);
        }

        // console.log('closeResultModal call', nextTimeStamp, timer, gameResultModalShown, showEndResultModal);
        if (nextTimeStamp && timer < 0 && gameResultModalShown && !showEndResultModal) {
          this.closeResultModal();
        }
      } else {
        if (gameState === 'choose' && currentTurn !== myPos && (timer === refetchLimit)) {
          this.fetchRoomDataFallback(false);
        }
        /* 
        if ((gameState === 'play' || gameState === 'burry') && !fastGame && currentTurn !== myPos && (timer === refetchLimit + 9 || timer === refetchLimit + 5)) {
        //  this.fetchRoomDataFallback(false);
        } else if (gameState === 'choose' && !fastGame && currentTurn !== myPos && (timer === refetchLimit + 8 || timer === refetchLimit + 5)) {
        //  this.fetchRoomDataFallback(false);
        } else if (currentTurn === myPos && timer === (((refetchLimit - 2) * 2) + 1)) {
        //  this.fetchRoomDataFallback(true);
        } else if (!fastGame && timer === refetchLimit - 6) {
        //  this.fetchRoomDataFallback(true);
        } else if (fastGame && currentTurn !== myPos && (timer === refetchLimit || timer === refetchLimit + 4)) {
        //  this.fetchRoomDataFallback(true);
        } else if (timer === refetchLimit + 1) {
        //  getOffset();
        //  fetchRoomDataFallback();
        } else if (timer === 3 || timer === -1 || timer === -5) {
        //  this.fetchRoomDataFallback(false);
        } */

        if (!roomClosed && nextTimeStamp && timer < 0 && !disableTimer) {
          this.endRoom();
          getOffset();
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  fetchRoomDataFallback = (tryDb) => {
    const { fetchRoomDataFallback, roomId } = this.props;

    const { hostname } = window.location;

    if (roomId) {
      fetchRoomDataFallback(roomId, hostname, tryDb || false);
    }
  }

  closeResultModal = () => {
    const { closeResultModal } = this.props;
    const { closeResultModalCalled } = this.state;

    if (!closeResultModalCalled) {
      this.setState({ closeResultModalCalled: true });

      closeResultModal();

      setTimeout(() => {
        this.setState({ closeResultModalCalled: false });
      }, 2000);
    }
  };

  endRoom = () => {
    const { endRoom } = this.props;
    const { endRoomCalled, timer } = this.state;

    if (!endRoomCalled) {
      let timeout = 4000;
      if (timer > -2 && timer <= 0) {
        timeout = 2000;
      } else if (timer > -3 && timer <= -2) {
        timeout = 4000;
      } else if (timer > -5 && timer <= -3) {
        timeout = 8000;
      } else if (timer > -6 && timer <= -5) {
        timeout = 12000;
      } else if (timer > -8 && timer <= -6) {
        timeout = 25000;
      } else if (timer <= -8) {
        timeout = 45000;
      }

      this.setState({ endRoomCalled: true });

      endRoom();

      setTimeout(() => {
        this.setState({ endRoomCalled: false });
      }, timeout);
    }
  };

  render() {
    const {
      speed, photo, notActive, isMainPlayer, gameState, screenMode, currentTurn, mWidth, key, uid,
    } = this.props;

    const {
      timer,
      percentage,
    } = this.state;

    // console.log('notActive', notActive, notActive ? `notactive${uid}` : `active${uid}`);
    console.log('render timer root', timer, percentage, gameState, currentTurn, uid);

    return (
      <div
        className="turn-timer-wrapper"
      >
        <CircularProgressbar
          // key={notActive ? `notactive${uid}` : `active${uid}`}
          uid={uid}
          progress={percentage <= 0 ? 0 : (percentage > 100 ? 100 : percentage)}
          progress2={66}
          text={`${timer <= 0 ? '0' : (timer > speed ? speed : timer)}`}
          size={110}
          notActive={notActive}
          strokeWidth={15}
          background={photo || (screenMode === SCREEN_MODE.light ? lightDefaultImage : defaultImage)}
          defaultFlag={photo ? false : true}
          isMainPlayer={isMainPlayer}
          circleOneStroke="rgba(197,79,79,0.6)"
          circleTwoStroke="rgba(251,216,90)"
          gameState={gameState}
          mWidth={mWidth}
          speed={speed}
          timer={timer}
          currentTurn={currentTurn}
          />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  offset: state.member.offset,
  nextTimeStamp: state.game.nextTimeStamp,
  gameState: state.game.globalParams.gameState,
  currentTurn: state.game.currentTurn,
  closeReason: state.game.globalParams.closeReason,
  disableTimer: state.game.globalParams.disableTimer,
  fastGame: state.game.globalParams.fastGame,
  lightningGame: state.game.globalParams.lightningGame,
  unlimitedGame: state.game.globalParams.unlimitedGame,
  roomClosed: state.game.globalParams.roomClosed,
  speed: state.game.globalParams.speed,
});

const mapDispatchToProps = {
  getOffset: getTimeOffset,
  fetchRoomDataFallback: roomDataFallbackNew,
};

export default connect(mapStateToProps, mapDispatchToProps)(TurnTimer);
