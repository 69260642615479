import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import TurnTimer from '../Players/TurnTimer';

import Row from 'reactstrap/lib/Row';

import PlayerType from '../Players/Components/PlayerType';
import PlayerInfo from '../Players/Components/PlayerInfo';
import PlayerGift from '../Players/Components/PlayerGift';
import PlayerEmotion from '../Players/Components/PlayerEmotion';
import BlockPlayer from '../Players/Components/BlockPlayer';
import PlayerInfoButton from '../Players/Components/PlayerInfoButton';
import SendGiftButton from '../Players/Components/SendGiftButton';
import ReportPlayer from '../Players/Components/ReportPlayer';
import NegativePlayer from '../Players/Components/NegativePlayer';
import PlayerModalRight from '../Players/Components/PlayerModalRight';
import PlayerModalRightMobile from '../Players/Components/PlayerModalRightMobile';

// import PlayerCards from '../Cards/PlayerCards';
import PlayersVisibleCards from './PlayersVisibleCards';
import QuitRound from '../Players/QuitRound';
import LastRound from '../Players/LastRound';

// import PlayerInfo2 from './PlayerInfo';

import myTurnSound from '../../../../../sounds/my_turn4.mp3';

// import { getPlayersArranged } from '../../../../../common/services/game-service-new';

import {
  getOtherUserInfo, getOtherUserVIPStatus,
} from '../../../../../actions/room';
import * as constants from '../../../../../constants/constants';

import { Media } from 'reactstrap';
import proIcon from '../../../../../images/icons/proIcon.svg';
import ozollapasAnimImg from '../../../../../images/Holiday/ozollapas.json';

import Lottie from 'react-lottie';


import CustomModal from '../../Components/Components/Modal';
import { getFriends } from '../../../../../actions/member';
import { Firebase } from '../../../../../lib/firebase';

class Players extends React.Component {
  static propTypes = {
    gameT: PropTypes.func.isRequired,
    endRoom: PropTypes.func.isRequired,
    players: PropTypes.shape().isRequired,
    roomId: PropTypes.string,
    uid: PropTypes.string,
    currentTurn: PropTypes.string,
    sittingOut: PropTypes.string,
    gameResultModalShown: PropTypes.bool,
    currentType: PropTypes.string,
    gifts: PropTypes.shape(),
    //  playerInfo: PropTypes.shape(),
    toggleGiftsModal: PropTypes.func.isRequired,
    roomGifts: PropTypes.shape(),
    ignoredUsers: PropTypes.shape(),
    //  unBlockUser: PropTypes.func.isRequired,
    //  toggleBlockUser: PropTypes.func.isRequired,
    closeResultModal: PropTypes.func.isRequired,
    fetchUserInfo: PropTypes.func.isRequired,
    myPos: PropTypes.string,
    largePlayer: PropTypes.string,
    gameState: PropTypes.string,
    talking: PropTypes.string,
    minGames: PropTypes.number,
    rPlayed: PropTypes.number,
    party: PropTypes.number,
    //  tournamentRoom: PropTypes.bool,
    soundOn: PropTypes.bool,
    lastRoundPlayer: PropTypes.string,
    lastRoundReason: PropTypes.string,
    showEndResultModal: PropTypes.bool,
    cards: PropTypes.arrayOf(PropTypes.shape()),
    playCardWrapper: PropTypes.func.isRequired,
    selectedCard: PropTypes.string,
    doubleClickSelectedCard: PropTypes.string,
    tableIsInProgress: PropTypes.bool,
    cardPlayClicked: PropTypes.bool,
    cardsOnTable: PropTypes.arrayOf(PropTypes.string),
    cardsOnLoading: PropTypes.bool,
    screenMode: PropTypes.string,
    tournamentRoom: PropTypes.bool,
    windowWidth: PropTypes.number,
    windowHeight: PropTypes.number,
    selectedTestHoliday: PropTypes.arrayOf(PropTypes.shape()),
    holidayTest: PropTypes.bool,
    socProvider: PropTypes.string,
    fetchFriends: PropTypes.func,
  };

  static defaultProps = {
    roomId: null,
    currentType: null,
    uid: null,
    currentTurn: null,
    sittingOut: null,
    gameResultModalShown: false,
    gifts: null,
    //  playerInfo: null,
    roomGifts: null,
    ignoredUsers: null,
    myPos: null,
    largePlayer: null,
    gameState: null,
    talking: null,
    minGames: null,
    rPlayed: null,
    party: null,
    //  tournamentRoom: false,
    soundOn: false,
    lastRoundPlayer: null,
    lastRoundReason: null,
    showEndResultModal: false,
    cards: [],
    selectedCard: null,
    doubleClickSelectedCard: null,
    tableIsInProgress: null,
    cardPlayClicked: null,
    cardsOnTable: [],
    cardsOnLoading: true,
    screenMode: constants.SCREEN_MODE.normal,
    tournamentRoom: false,
    windowWidth: null,
    windowHeight: null,
    socProvider: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      playersArranged: [],
      blockPopoverOpen: false,
      lastRoundPlayerName: null,
      hurryUpTimerEnabled: false,
    };
    this.myTurnAudio = new Audio(myTurnSound);
  }

  componentDidMount() {
    const { players, myPos, sittingOut } = this.props;
    //  const existingPlayersArranged = this.state.playersArranged;

    // const playersArranged = getPlayersArranged(players, myPos, sittingOut);

    let playersArranged = [];

    if (myPos === 'player1') {
      playersArranged[0] = { ...players.player2, position: 'player2' };
      playersArranged[1] = { ...players.player1, position: 'player1' };
    } else if (myPos === 'player2') {
      playersArranged[0] = { ...players.player1, position: 'player1' };
      playersArranged[1] = { ...players.player2, position: 'player2' };
    }

    console.log('playersArranged', { playersArranged });

    if (playersArranged?.length > 0) {
      this.setState({ playersArranged: [...playersArranged] });
    }

    const {
      fetchFriends, socProvider,
    } = this.props;
    const { hostname } = window.location;

    let devMode = false;

    if (hostname && (hostname.includes('dev') || hostname.includes('localhost'))) {
      devMode = true;
    }
    this.setState({ devMode });

    if (socProvider === 'facebook') {
      Firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          window.FB.getLoginStatus((response) => {
            if (response.status === 'connected') {
              const { accessToken } = response.authResponse;

              window.FB.api(
                `/${user.providerData[0].uid}/friends`,
                'GET',
                { access_token: accessToken },
                (resp) => {
                  //  this.setState({ friends: resp.data });

                  console.log(resp);
                  if (resp && resp.data) {
                    fetchFriends(resp).then((res) => {
                      console.log(res);
                    });
                  }
                },
              );
            }
          });
        } else {
          //  console.log('no user');
        }
      });
    } else if (socProvider === 'draugiem') {

      fetchFriends(devMode);
    } else {
      fetchFriends();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { fetchOtherUserVIPStatus } = this.props;
    const { players, myPos, sittingOut } = nextProps;
    const existingPlayersArranged = this.state.playersArranged;

    const { fetcherVIPData } = this.state;

    // const playersArranged = getPlayersArranged(players, myPos, sittingOut);

    let playersArranged = [];

    if (myPos === 'player1') {
      playersArranged[0] = { ...players.player2, position: 'player2' };
      playersArranged[1] = { ...players.player1, position: 'player1' };
    } else if (myPos === 'player2') {
      playersArranged[0] = { ...players.player1, position: 'player1' };
      playersArranged[1] = { ...players.player2, position: 'player2' };
    }

    if (!fetcherVIPData && players && players.player1 && players.player1.uid && players.player2 && players.player2.uid) {
      Object.keys(players).map((key) => {
        if (key !== myPos) {
          console.log('call fetchOtherUserVIPStatus', players[key]);
          fetchOtherUserVIPStatus(players[key].uid);
        }
      });
      this.setState({ fetcherVIPData: true });
    }

    if (sittingOut) {
      if (playersArranged?.length > 0 && ((existingPlayersArranged.length === 3 && playersArranged?.length === 2) || existingPlayersArranged.length === 0 || (!existingPlayersArranged[0].uid || !existingPlayersArranged[1].uid))) {
        console.log('update playersArranged 1', { playersArranged, players });
        this.setState({ playersArranged });
      }
    } else if (playersArranged?.length > 0 && ((existingPlayersArranged.length === 3 && playersArranged?.length === 2) || existingPlayersArranged.length === 0 || (!existingPlayersArranged[0].uid || !existingPlayersArranged[1].uid))) {
      console.log('update playersArranged 2', { playersArranged, players, existingPlayersArranged });
      this.setState({ playersArranged });

      /*
      if (playersArranged[0].uid || playersArranged[1].uid) {
        playersArranged.map((player) => {
          if (player.position !== myPos) {
            console.log('call fetchOtherUserVIPStatus', player);
            fetchOtherUserVIPStatus(player.uid);
          }
        });
      } */
    } else if (playersArranged && playersArranged.length && existingPlayersArranged && existingPlayersArranged.length) {
      if (playersArranged[0].uid !== existingPlayersArranged[0].uid || playersArranged[0].name !== existingPlayersArranged[0].name || playersArranged[0].photo !== existingPlayersArranged[0].photo) {
        console.log('update playersArranged 3', { playersArranged, players });
        this.setState({ playersArranged });
      } else if (playersArranged[1].uid !== existingPlayersArranged[1].uid || playersArranged[1].name !== existingPlayersArranged[1].name || playersArranged[1].photo !== existingPlayersArranged[1].photo) {
        console.log('update playersArranged 4', { playersArranged, players });
        this.setState({ playersArranged });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      gameState, talking, uid, currentTurn, soundOn, myPos, lastRoundPlayer, roomGifts, setWineGiftTimerFunc, setWineGiftSenderFunc,
    } = this.props;
    const { gameState: prevGameState, currentTurn: prevCurrentTurn, talking: prevTalking, roomGifts: prevRoomGifts } = prevProps;

    const { lastRoundPlayerName } = this.state;

    if (!lastRoundPlayerName && lastRoundPlayer) {
      const { players } = this.props;

      if (players) {
        let playerName;

        if (players.player1 && players.player1.uid === lastRoundPlayer) {
          playerName = players.player1.name;
        } else if (players.player2 && players.player2.uid === lastRoundPlayer) {
          playerName = players.player2.name;
        } else if (players.player3 && players.player3.uid === lastRoundPlayer) {
          playerName = players.player3.name;
        } else if (players.player4 && players.player4.uid === lastRoundPlayer) {
          playerName = players.player4.name;
        }

        if (playerName) {
          this.setState({ lastRoundPlayerName: playerName });
        }
      }
    }

    if (soundOn) {
      const turnChanged = prevCurrentTurn !== currentTurn;

      if ((prevGameState !== 'choose' || !prevTalking) && gameState === 'choose' && uid && talking && talking === uid) {
        const playPromise = this.myTurnAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      } else if (gameState === 'burry' && (myPos && myPos === currentTurn) && turnChanged) {
        const playPromise = this.myTurnAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
    }

  }

  openPlayerModal = (uid, position, posClassName, photo, name) => {
    const { fetchUserInfo } = this.props;

    fetchUserInfo(uid);

    setTimeout(() => {
      this.setState({
        openedPlayerModal: position,
        openedPlayerModalUid: uid,
        openedPlayerModalPosClassName: posClassName,
        openedPlayerModalPhoto: photo,
        openedPlayerModalName: name,

        blockPopoverOpen: null
      });
    }, 200);
  }

  closePlayerModal = () => {
    this.setState({
      openedPlayerModal: null,
      openedPlayerModalUid: null,
      openedPlayerModalPosClassName: null,
      openedPlayerModalPhoto: null,
      openedPlayerModalName: null,
      blockPopoverOpen: null
    });
  }

  /* toggleBlockUser = (uid, name) => {
    const { tournamentRoom, toggleBlockUser } = this.props;
    if (!tournamentRoom) {
      toggleBlockUser(uid, name);
      this.closePlayerModal();
    }

    this.setState({ blockPopoverOpen: null });
  } */


  onBlockHover = (type, index) => {
    if (type === 'enter') {
      this.setState({ blockPopoverOpen: index });
    } else {
      this.setState({ blockPopoverOpen: null });
    }
  }

  render() {
    const {
      players,
      myPos,
      roomId,
      gameT,
      uid,
      currentTurn,
      sittingOut,
      largePlayer,
      currentType,
      gameState,
      talking,
      minGames,
      party,
      rPlayed,
      endRoom,
      gameResultModalShown,
      gifts,
      toggleGiftsModal,
      roomGifts,
      ignoredUsers,
      closeResultModal,
      lastRound,
      lastRoundReason,
      //  playerInfo,
      showEndResultModal,

      cards,
      soundOn,
      playCardWrapper,
      selectedCard,
      doubleClickSelectedCard,
      tableIsInProgress,
      cardPlayClicked,
      cardsOnTable,
      cardsOnLoading,
      screenMode,
      tournamentRoom,
      windowWidth,
      windowHeight,
      currentHand,
      roomClosed,
      selectedTestHoliday,
      holidayTest,
      playerInfo,

      visibleCards,
      otherVisibleCards,
      hiddenCards,
      otherHiddenCards,
      studentRoom,

      disabledEmoticons,
      disabledReceivingGifts,
      disabledShowGifts,
      vipUntil,
      offset,
      playerVIPInfo,
    } = this.props;

    const mobileCardLayout = windowWidth <= 1280 || windowWidth <= windowHeight; // TODO: remove screenWidth <= screenHeight once card mobile layout issue is fixed

    const x = new Date();
    const date = x.getDate();
    const month = x.getMonth();

    const {
      playersArranged,
      blockPopoverOpen,
      lastRoundPlayerName,
      hurryUpTimerEnabled,
      openedPlayerModal,
      openedPlayerModalUid,
      openedPlayerModalPosClassName,
      openedPlayerModalPhoto,
      openedPlayerModalName,
    } = this.state;

    console.log('Players disabledEmoticons', disabledEmoticons, vipUntil, disabledReceivingGifts, disabledShowGifts, playerVIPInfo);

    console.log('hiddenCards', hiddenCards, visibleCards, otherHiddenCards, otherVisibleCards);

    const { speed } = this.props;
    const isSpeedUnlimited = speed === 60;

    const currentPlayerArray = playersArranged.filter(player => player.uid && player.uid === uid);
    const currentPlayer = currentPlayerArray[0] ? currentPlayerArray[0] : null;

    console.log('playersArranged', playersArranged);

    let playerHasToPutCard = false;
    if (currentPlayer) {
      playerHasToPutCard = ((gameState === 'play' && currentPlayer.position === currentTurn) || (gameState === 'play' && currentPlayer.position === myPos && currentTurn === null))
        || (gameState === 'burry' && currentPlayer.position === currentTurn)
        || (talking && currentPlayer.uid && gameState === 'choose' && talking === currentPlayer.uid);
    }
    const rowStyle = { position: 'absolute', width: '100%', height: '100%', overflow: 'hidden', zIndex: 999, pointerEvents: 'none' };
    return (
      <Fragment>
        {playersArranged && playersArranged.map((player, index) => {
          let posClassName;

          if (index === 0) {
            posClassName = 'player-top';
          } else if (index === 1) {
            posClassName = 'player-firstperson';
          } else if (index === 2) {
          //  posClassName = 'player-right';
          } else if (index === 3) {
          //  posClassName = 'player-top';
          }

          if (!posClassName) {
            return null;
          }

          if (index === 1) {
            return (
              <div key={`${player.position}-${party}-${index}`} style={rowStyle}>
                {player && player.uid && (
                  <Row className='mobile-player-pos' style={{ height: '31vh', position: 'absolute', width: '100%', bottom: 0, margin: 0 }}>
                    <div style={{ width: '40vw', marginLeft: 'auto', marginRight: 'auto', position: 'relative' }}>
                      <div className="player-firstperson-background">
                        <div className="player-firstperson-background-shadow" />
                        <div className="player-firstperson-background-shadow2" />
                      </div>
                      <div
                        id={`player-${player.position}`}
                        className={`player player-${player.position} ${((gameState === 'play' || gameState === 'results') && player.position === currentTurn) && ' is-player-turn'} ${posClassName} mobile-player-main-pos ${posClassName}-mobile`}
                        style={{ pointerEvents: 'all' }}
                      >
                        <div className="cards2-wrapper">
                          <QuitRound mWidth={windowWidth} soundOn={soundOn} roomId={roomId} enabled={gameState === 'play' && largePlayer && myPos === largePlayer} studentRoom={studentRoom} />
                          <QuitRound mWidth={windowWidth} soundOn={soundOn} roomId={roomId} enabled={gameState === 'play' && currentType === 'galdins' && myPos !== sittingOut} studentRoom={studentRoom} />

                          {/* <PlayerCards
                            cards={cards}
                            playCard={playCardWrapper}
                            selectedCard={selectedCard}
                            doubleClickSelectedCard={doubleClickSelectedCard}
                            tableIsInProgress={tableIsInProgress}
                            cardPlayClicked={cardPlayClicked}
                            soundOn={soundOn}
                            cardsOnTable={cardsOnTable}
                            currentType={currentType}
                            cardsOnLoading={cardsOnLoading}
                          /> */}

                          <PlayersVisibleCards
                            hiddenCards={hiddenCards}
                            visibleCards={visibleCards}
                            otherHiddenCards={otherHiddenCards}
                            otherVisibleCards={otherVisibleCards}
                            cards={cards}
                            playCard={playCardWrapper}
                            selectedCard={selectedCard}
                            doubleClickSelectedCard={doubleClickSelectedCard}
                            tableIsInProgress={tableIsInProgress}
                            cardPlayClicked={cardPlayClicked}
                            soundOn={soundOn}
                            cardsOnTable={cardsOnTable}
                            currentType={currentType}
                            cardsOnLoading={cardsOnLoading}
                          />

                          <LastRound
                            soundOn={soundOn}
                            roomId={roomId}
                            lastRound={lastRound}
                            lastRoundPlayerName={lastRoundPlayerName}
                            lastRoundReason={lastRoundReason}
                            minGames={minGames}
                            rPlayed={rPlayed}
                            playerHasToPutCard={playerHasToPutCard}
                            isSpeedUnlimited={isSpeedUnlimited}
                            currentPlayerPosition={currentPlayer?.position}
                            studentRoom={studentRoom}
                          />
                        </div>

                        <div className="player-image-timer-wrapper player-image-timer-wrapper-new">

                          <div className="my-info-ratings-step-wrapper my-info-ratings-step-wrapper-ingame">
                            {player.lvl < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-weak my-info-ratings-step-active">{gameT('common:myInfo.weak')}</div>) : (null)}
                            {(player.lvl >= 10 && player.lvl < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-beginner my-info-ratings-step-active">{gameT('common:myInfo.beginner')}</div>) : (null)}
                            {(player.lvl >= 20 && player.lvl < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-untrained my-info-ratings-step-active">{gameT('common:myInfo.untrained')}</div>) : (null)}
                            {(player.lvl >= 30 && player.lvl < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-medium my-info-ratings-step-active">{gameT('common:myInfo.medium')}</div>) : (null)}
                            {(player.lvl >= 40 && player.lvl < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-good my-info-ratings-step-active">{gameT('common:myInfo.good')}</div>) : (null)}
                            {(player.lvl >= 50 && player.lvl < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-great my-info-ratings-step-active">{gameT('common:myInfo.great')}</div>) : (null)}
                            {(player.lvl >= 60 && player.lvl < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-fan my-info-ratings-step-active">{gameT('common:myInfo.fan')}</div>) : (null)}
                            {player.lvl >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-pro my-info-ratings-step-active"><Media src={proIcon} alt="X" /></div>) : (null)}
                          </div>
                          <TurnTimer
                            endRoom={endRoom}
                            roomId={roomId}
                            photo={player.photo || ''}
                            notActive={!(uid && !roomClosed && ((gameState === 'choose' && talking && talking === player.uid)
                              || (gameState === 'play' && currentTurn && player.position === currentTurn)
                              || (gameState === 'burry' && currentTurn && player.position === currentTurn)
                            ))}
                            isMainPlayer={player.uid === uid}
                            isRunning={(((gameState === 'play' && player.position === currentTurn) || (gameState === 'play' && player.position === myPos && currentTurn === null))
                              || (gameState === 'burry' && player.position === currentTurn)
                              || (talking && player.uid && gameState === 'choose' && talking === player.uid)
                              || (uid && player.uid && gameState === 'results' && player.uid === uid))}
                            closeResultModal={closeResultModal}
                            gameResultModalShown={gameResultModalShown}
                            myPos={myPos}
                            showEndResultModal={showEndResultModal}
                            screenMode={screenMode}
                            mWidth={windowWidth}
                          />

                          {(uid !== player.uid) && (
                            <SendGiftButton
                              uid={player.uid}
                              toggleGiftsModal={toggleGiftsModal}
                              screenMode={screenMode} t={gameT}
                              disabled={playerVIPInfo && playerVIPInfo[player.uid] && playerVIPInfo[player.uid].disabledReceivingGifts && playerVIPInfo[player.uid].vipUntil && playerVIPInfo[player.uid].vipUntil >= (Date.now() + offset)}
                            />
                          )}

                          <BlockPlayer
                            uid={player.uid}
                            name={player.name}
                            index={index}
                            isIgnored={ignoredUsers && ignoredUsers[player.uid]}
                            blockPopoverOpen={blockPopoverOpen}
                            tournamentRoom={tournamentRoom}
                          />
                          <NegativePlayer
                            bal={players[player.position].bal}
                            index={index}
                          />

                          <PlayerInfoButton
                            openPlayerModal={this.openPlayerModal}
                            uid={player.uid}
                            name={player.name}
                            position={player.position}
                            photo={player.photo || ''}
                            closePlayerModal={this.closePlayerModal}
                            playerPosition={player.position}
                            playerUid={player.uid}
                            posClassName={posClassName}
                            t={gameT}
                            screenMode={screenMode}
                            toggleGiftsModal={toggleGiftsModal}
                            isIgnored={ignoredUsers && ignoredUsers[player.uid]}
                            tournamentRoom={tournamentRoom}
                          />
                        </div>

                        <PlayerGift
                          index={index}
                          gifts={gifts}
                          uid={player.uid}
                          userGift={roomGifts ? roomGifts[player.uid] : null}
                          posClassName={posClassName}
                          hidden={(disabledReceivingGifts || disabledShowGifts) && vipUntil && vipUntil >= (Date.now() + offset)}
                        />

                        {(largePlayer) ? (
                          <PlayerType
                            t={gameT}
                            currentType={currentType}
                            sittingOut={sittingOut && player.position === sittingOut}
                            gameState={gameState}
                            largePlayer={!!(largePlayer && player.position === largePlayer)}
                          />
                        ) : (
                          <Fragment>
                            {gameState === 'choose' || gameResultModalShown ? (
                              (sittingOut && player.position === sittingOut) ? (
                                <div className="player-type-wrapper">
                                  <div className="player-type-mazais">{gameT('dealer')}</div>
                                </div>
                              ) : (<div className="player-type-none" />)
                            ) : (sittingOut && player.position === sittingOut) ? (
                              <div className="player-type-wrapper">
                                <div className="player-type-mazais">{gameT('dealer')}</div>
                              </div>
                            ) : (
                              // <div className="player-type-none" />
                              <div className="player-type-wrapper">
                                <div className="player-type-mazais">{gameT('table')}</div>
                              </div>
                            )}
                          </Fragment>
                        )}

                        <PlayerInfo
                          name={player.name}
                          bal={(player.position && players && players[player.position]) ? players[player.position].bal : null}
                          largePlayer={!!(largePlayer && player.position === largePlayer)}
                          uid={player.uid}
                          myId={uid}
                          activePos={(uid && ((gameState === 'choose' && talking && talking === player.uid)
                            || (gameState === 'play' && currentTurn && player.position === currentTurn)
                            || (gameState === 'burry' && currentTurn && player.position === currentTurn)))}
                          screenMode={screenMode}

                          gameState={gameState}
                          currentHand={currentHand}
                          playerPosition={player.position}
                          currentTurn={currentTurn}
                        />

                        {((date && month && (date === 23 || date === 24) && month === 5) || (holidayTest && (selectedTestHoliday[0].id === 'ligo' || selectedTestHoliday[0].id === 'jani'))) && (
                          <div className="ozollapas-image-new">
                            <Lottie
                              options={{
                                loop: true,
                                autoplay: true,
                                animationData: ozollapasAnimImg,
                                rendererSettings: {
                                  preserveAspectRatio: 'xMidYMid slice',
                                },
                              }}
                              height={100}
                              width={60}
                              isStopped={false}
                              isPaused={false}
                            />
                          </div>
                        )}
                        <PlayerEmotion
                          emotion={player.position && players[player.position] ? players[player.position].emotion : null}
                          posClassName={posClassName}
                          newDesign
                          userGift={roomGifts ? roomGifts[player.uid] : null}
                          hidden={disabledEmoticons && vipUntil && vipUntil >= (Date.now() + offset)}
                        />

                      </div>
                    </div>
                  </Row>
                )}
              </div>
            );
          }

          return (
            <div key={`${player.position}-${party}-${index}`}>
              {player && player.uid && (
                <div
                  id={`player-${player.position}`}
                  className={`player player-${player.position} ${((gameState === 'play' || gameState === 'results') && player.position === currentTurn) && ' is-player-turn'} ${posClassName} ${posClassName}-mobile`}
                >
                  {index === 1 ? (
                    <div className="cards2-wrapper">
                      <QuitRound soundOn={soundOn} roomId={roomId} enabled={gameState === 'play' && largePlayer && myPos === largePlayer} studentRoom={studentRoom} />
                      {/*<PlayerCards
                        cards={cards}
                        playCard={playCardWrapper}
                        selectedCard={selectedCard}
                        doubleClickSelectedCard={doubleClickSelectedCard}
                        tableIsInProgress={tableIsInProgress}
                        cardPlayClicked={cardPlayClicked}
                        soundOn={soundOn}
                        cardsOnLoading={cardsOnLoading}
                      /> */}

                      <PlayersVisibleCards
                        hiddenCards={hiddenCards}
                        visibleCards={visibleCards}
                        otherHiddenCards={otherHiddenCards}
                        otherVisibleCards={otherVisibleCards}
                        cards={cards}
                        playCard={playCardWrapper}
                        selectedCard={selectedCard}
                        doubleClickSelectedCard={doubleClickSelectedCard}
                        tableIsInProgress={tableIsInProgress}
                        cardPlayClicked={cardPlayClicked}
                        soundOn={soundOn}
                        cardsOnTable={cardsOnTable}
                        currentType={currentType}
                        cardsOnLoading={cardsOnLoading}
                      />

                      <LastRound
                        soundOn={soundOn}
                        roomId={roomId}
                        lastRoundPlayerName={lastRoundPlayerName}
                        lastRoundReason={lastRoundReason}
                        minGames={minGames}
                        rPlayed={rPlayed}
                        screenMode={screenMode}
                        playerHasToPutCard={playerHasToPutCard}
                        isSpeedUnlimited={isSpeedUnlimited}
                        currentPlayerPosition={currentPlayer?.position}
                        studentRoom={studentRoom}
                      />
                    </div>
                  ) : (null)}

                  <div className="player-image-timer-wrapper player-image-timer-wrapper-new">
                    <div className="my-info-ratings-step-wrapper my-info-ratings-step-wrapper-ingame">
                      {player.lvl < 10 ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-weak my-info-ratings-step-active">{gameT('common:myInfo.weak')}</div>) : (null)}
                      {(player.lvl >= 10 && player.lvl < 20) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-beginner my-info-ratings-step-active">{gameT('common:myInfo.beginner')}</div>) : (null)}
                      {(player.lvl >= 20 && player.lvl < 30) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-untrained my-info-ratings-step-active">{gameT('common:myInfo.untrained')}</div>) : (null)}
                      {(player.lvl >= 30 && player.lvl < 40) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-medium my-info-ratings-step-active">{gameT('common:myInfo.medium')}</div>) : (null)}
                      {(player.lvl >= 40 && player.lvl < 50) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-good my-info-ratings-step-active">{gameT('common:myInfo.good')}</div>) : (null)}
                      {(player.lvl >= 50 && player.lvl < 60) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-great my-info-ratings-step-active">{gameT('common:myInfo.great')}</div>) : (null)}
                      {(player.lvl >= 60 && player.lvl < 70) ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-fan my-info-ratings-step-active">{gameT('common:myInfo.fan')}</div>) : (null)}
                      {player.lvl >= 70 ? (<div className="my-info-ratings-step my-info-ratings-step-ingame my-info-ratings-step-pro my-info-ratings-step-active"><Media src={proIcon} alt="X" /></div>) : (null)}
                    </div>
                    {(uid && !roomClosed && ((gameState === 'choose' && talking && talking === player.uid)
                      || (gameState === 'play' && currentTurn && player.position === currentTurn)
                      || (gameState === 'burry' && currentTurn && player.position === currentTurn)
                      || (gameState === 'results' && player.uid === uid))) ? (
                      <TurnTimer
                        endRoom={endRoom}
                        roomId={roomId}
                        photo={player.photo || ''}
                        notActive={false}
                        uid={player.uid}
                        isMainPlayer={player.uid === uid}
                        isRunning={(((gameState === 'play' && player.position === currentTurn) || (gameState === 'play' && player.position === myPos && currentTurn === null))
                          || (gameState === 'burry' && player.position === currentTurn)
                          || (talking && player.uid && gameState === 'choose' && talking === player.uid)
                          || (uid && player.uid && gameState === 'results' && player.uid === uid))}
                        closeResultModal={closeResultModal}
                        gameResultModalShown={gameResultModalShown}
                        myPos={myPos}
                        showEndResultModal={showEndResultModal}
                        mWidth={windowWidth}
                      />
                    ) : (
                      <TurnTimer
                        endRoom={endRoom}
                        roomId={roomId}
                        photo={player.photo || ''}
                        notActive
                        uid={player.uid}
                        isRunning={(((gameState === 'play' && player.position === currentTurn) || (gameState === 'play' && player.position === myPos && currentTurn === null))
                          || (gameState === 'burry' && player.position === currentTurn)
                          || (talking && player.uid && gameState === 'choose' && talking === player.uid)
                          || (uid && player.uid && gameState === 'results' && player.uid === uid))}
                        closeResultModal={closeResultModal}
                        gameResultModalShown={gameResultModalShown}
                        myPos={myPos}
                        showEndResultModal={showEndResultModal}
                        mWidth={windowWidth}
                      />
                    )}
                    <SendGiftButton
                      uid={player.uid}
                      toggleGiftsModal={toggleGiftsModal}
                      screenMode={screenMode}
                      t={gameT}
                      disabled={playerVIPInfo && playerVIPInfo[player.uid] && playerVIPInfo[player.uid].disabledReceivingGifts && playerVIPInfo[player.uid].vipUntil && playerVIPInfo[player.uid].vipUntil >= (Date.now() + offset)}
                    />

                    <PlayerGift
                      index={index}
                      gifts={gifts}
                      uid={player.uid}
                      userGift={roomGifts ? roomGifts[player.uid] : null}
                      posClassName={posClassName}
                      hidden={(disabledReceivingGifts || disabledShowGifts) && vipUntil && vipUntil >= (Date.now() + offset)}
                    />

                    <BlockPlayer
                      uid={player.uid}
                      name={player.name}
                      index={index}
                      isIgnored={ignoredUsers && ignoredUsers[player.uid]}
                      blockPopoverOpen={blockPopoverOpen}
                      tournamentRoom={tournamentRoom}
                      screenMode={screenMode}
                    />

                    <PlayerInfoButton
                      openPlayerModal={this.openPlayerModal}
                      uid={player.uid}
                      name={player.name}
                      position={player.position}
                      t={gameT}
                      closePlayerModal={this.closePlayerModal}
                      playerPosition={player.position}
                      playerUid={player.uid}
                      posClassName={posClassName}
                      photo={player.photo}
                      screenMode={screenMode}
                      toggleGiftsModal={toggleGiftsModal}
                      isIgnored={ignoredUsers && ignoredUsers[player.uid]}
                      tournamentRoom={tournamentRoom}
                    />

                    <ReportPlayer
                      name={player.name}
                      uid={player.uid}
                      myId={uid}
                      roomId={roomId}
                    />
                  </div>
                  {(largePlayer) ? (
                    <PlayerType
                      t={gameT}
                      currentType={currentType}
                      sittingOut={sittingOut && player.position === sittingOut}
                      gameState={gameState}
                      largePlayer={!!(largePlayer && player.position === largePlayer)}
                    />
                  ) : (
                    <Fragment>
                      {gameState === 'choose' || gameResultModalShown ? (
                        (sittingOut && player.position === sittingOut) ? (
                          <div className="player-type-wrapper">
                            <div className="player-type-mazais">{gameT('dealer')}</div>
                          </div>
                        ) : (<div className="player-type-none" />)
                      ) : (sittingOut && player.position === sittingOut) ? (
                        <div className="player-type-wrapper">
                          <div className="player-type-mazais">{gameT('dealer')}</div>
                        </div>
                      ) : (
                        <div className="player-type-wrapper">
                          <div className="player-type-mazais">{gameT('table')}</div>
                        </div>
                      )}
                    </Fragment>
                  )}

                  <PlayerInfo
                    name={player.name}
                    bal={(player.position && players && players[player.position]) ? players[player.position].bal : null}
                    largePlayer={!!(largePlayer && player.position === largePlayer)}
                    uid={player.uid}
                    myId={uid}
                    activePos={(uid && ((gameState === 'choose' && talking && talking === player.uid)
                      || (gameState === 'play' && currentTurn && player.position === currentTurn)
                      || (gameState === 'burry' && currentTurn && player.position === currentTurn)))}
                    screenMode={screenMode}

                    gameState={gameState}
                    currentHand={currentHand}
                    playerPosition={player.position}
                    currentTurn={currentTurn}
                  />

                  {((date && month && (date === 23 || date === 24) && month === 5) || (holidayTest && (selectedTestHoliday[0].id === 'ligo' || selectedTestHoliday[0].id === 'jani'))) && (
                    <div className="ozollapas-image-new">
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: ozollapasAnimImg,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        height={100}
                        width={60}
                        isStopped={false}
                        isPaused={false}
                      />
                    </div>
                  )}

                  <PlayerEmotion
                    emotion={player.position && players[player.position] ? players[player.position].emotion : null}
                    posClassName={posClassName}
                    newDesign
                    userGift={roomGifts ? roomGifts[player.uid] : null}
                    hidden={disabledEmoticons && vipUntil && vipUntil >= (Date.now() + offset)}
                  />
                </div>
              )}
            </div>
          );
        })}

        <>
          {!mobileCardLayout ? (
            <PlayerModalRight
              gifts={gifts}
              playerInfo={playerInfo}
              closePlayerModal={this.closePlayerModal}
              modalOpen={openedPlayerModal}
              posClassName={openedPlayerModalPosClassName}

              uid={openedPlayerModalUid}
              photo={openedPlayerModalPhoto}
              t={gameT}
              screenMode={screenMode}
              position={openedPlayerModal}
            />
          ) : (
            <CustomModal
              isOpen={openedPlayerModal}
              addClassName="mobile-player-info-modal d-none"
              toggle={this.closePlayerModal}
              body={(
                <PlayerModalRightMobile
                  gifts={gifts}
                  playerInfo={playerInfo}
                  closePlayerModal={this.closePlayerModal}
                  modalOpen
                  posClassName={openedPlayerModalPosClassName}
                  uid={openedPlayerModalUid}
                  name={openedPlayerModalName}
                  photo={openedPlayerModalPhoto}
                  t={gameT}
                  screenMode={screenMode}
                  position={openedPlayerModal}
                  toggleGiftsModal={toggleGiftsModal}
                  isIgnored={ignoredUsers && ignoredUsers[openedPlayerModalUid]}

                  tournamentRoom={tournamentRoom}
                />
              )}
              footerClose={false}
            />
          )}

        </>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { roomId } = ownProps;
  return {
    players: state.game.players,
    uid: state.member.uid,
    disabledEmoticons: state.member.disabledEmoticons || null,
    disabledReceivingGifts: state.member.disabledReceivingGifts || null,
    disabledShowGifts: state.member.disabledShowGifts || null,
    vipUntil: state.member.vipUntil,
    offset: state.member.offset,
    ignoredUsers: state.member.ignoredUsers,
    currentTurn: state.game.currentTurn,
    sittingOut: state.game.sittingOut,
    currentType: state.game.currentType,
    largePlayer: state.game.largePlayer,
    gameState: state.game.globalParams.gameState,
    talking: state.game.globalParams.talking,
    lastRound: state.game.globalParams.lastRound,
    minGames: state.game.globalParams.minGames,
    party: state.game.globalParams.party,
    rPlayed: state.game.globalParams.rPlayed,
    lastRoundPlayer: state.game.globalParams.lastRoundPlayer || null,
    lastRoundReason: state.game.globalParams.lastRoundReason || null,
    currentHand: state.game.globalParams.currentHand,
    tournamentRoom: state.game.globalParams.tournamentRoom,
    gifts: state.rooms.gifts || null,
    cards: state.game.cards || [],
    visibleCards: state.game.visibleCards || [],
    otherVisibleCards: state.game.otherVisibleCards || [],
    hiddenCards: state.game.hiddenCards || [],
    otherHiddenCards: state.game.otherHiddenCards || [],
    roomGifts: state.rooms.roomGifts ? state.rooms.roomGifts[roomId] : null,
    cardsOnTable: state.game.cardsOnTable,
    speed: state.game.globalParams.speed,
    roomClosed: state.game.globalParams.roomClosed,
    playerInfo: state.rooms.playerInfo || null,
    socProvider: state.member.socProvider || null,
    playerVIPInfo: state.rooms.playerVIPInfo || null,
  };
};

const mapDispatchToProps = {
  fetchUserInfo: getOtherUserInfo,
  fetchOtherUserVIPStatus: getOtherUserVIPStatus,
  fetchFriends: getFriends,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('notifications')(Players));
