import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import CustomModal from '../../Components/Components/Modal';

import {
  claimChestBonus,
  claimAdventBonus,
  closeEmailVerifyBonusModal,
} from '../../../../../actions/member';
import * as constants from '../../../../../constants/constants';

class BonusModals extends React.PureComponent {
    static propTypes = {
      showChestBonusModal: PropTypes.bool,
      showAdventBonusModal: PropTypes.bool,
      showEmailVerifyBonusModal: PropTypes.bool,
      t: PropTypes.func.isRequired,
      claimChestBonusFunc: PropTypes.func.isRequired,
      claimAdventBonusFunc: PropTypes.func.isRequired,
      closeEmailVerifyBonus: PropTypes.func.isRequired,
      newVersion: PropTypes.string,
      holidayModalOpen: PropTypes.bool,
      toggleBonusModal: PropTypes.func.isRequired,
      openModal: PropTypes.bool,
    }

    static defaultProps = {
      showChestBonusModal: false,
      showAdventBonusModal: false,
      showEmailVerifyBonusModal: false,
      newVersion: '',
      holidayModalOpen: false,
      openModal: false,
    }

    componentDidUpdate(prevProps) {
      const { showChestBonusModal, showAdventBonusModal, toggleBonusModal } = this.props;

      if ((!prevProps.showChestBonusModal && showChestBonusModal) || (!prevProps.showAdventBonusModal && showAdventBonusModal)) {
        toggleBonusModal(true);
      }
    }

    claimChestBonusFuncA = () => {
      const { claimChestBonusFunc, toggleBonusModal } = this.props;
      claimChestBonusFunc();
      toggleBonusModal(false);
    }

    claimAdventBonusFuncA = () => {
      const { claimAdventBonusFunc, toggleBonusModal } = this.props;
      claimAdventBonusFunc();
      toggleBonusModal(false);
    }

    render() {
      const {
        showChestBonusModal, showAdventBonusModal, t, claimChestBonusFunc, claimAdventBonusFunc, newVersion, openModal, holidayModalOpen, showEmailVerifyBonusModal, closeEmailVerifyBonus,
      } = this.props;

      console.log('Bonus modals', showEmailVerifyBonusModal);

      if (newVersion && newVersion > constants.NEW_VERSION && !openModal && !holidayModalOpen) {
        if (showChestBonusModal) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={this.claimChestBonusFuncA}
              size="lg"
              title={null}
              body={(
                <>
                  {t('claimChestBonus')}
                </>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimChestBonusFuncA()}>{t('thanks')}</Button>
              )}
            />
          );
        }
        if (showAdventBonusModal) {
          return (
            <CustomModal
              isOpen
              className="new-version-modal"
              toggle={this.claimAdventBonusFuncA}
              size="lg"
              title={null}
              body={(
                <>
                  {t('claimAdventBonus')}
                </>
              )}
              footer={(
                <Button color="link" className="notification-footer-button" onClick={() => this.claimAdventBonusFuncA()}>{t('thanks')}</Button>
              )}
            />
          );
        }
      }
      if (!openModal && !holidayModalOpen && showEmailVerifyBonusModal) {
        return (
          <CustomModal
            isOpen={showEmailVerifyBonusModal}
            toggle={closeEmailVerifyBonus}
            size="md"
            verticalCentering
            largeText
            title={t('common:common.congratulations')}
            footer={(
              <Button className="modal-footer-button tutorial-result-button mb-3" color="secondary" onClick={closeEmailVerifyBonus}>
                {t('common:common.ok')}
              </Button>
            )}
            body={(
              <>
                {t('updateProfile:earnedReward')}
              </>
            )}
          />
        );
      }
      return null;
    }
}

const mapStateToProps = state => ({
  showChestBonusModal: state.member.showChestBonusModal || false,
  showAdventBonusModal: state.member.showAdventBonusModal || false,
  showEmailVerifyBonusModal: state.member.showEmailVerifyBonusModal || false,
});

const mapDispatchToProps = {
  claimChestBonusFunc: claimChestBonus,
  claimAdventBonusFunc: claimAdventBonus,
  closeEmailVerifyBonus: closeEmailVerifyBonusModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['holiday', 'updateProfile', 'common'])(BonusModals));
