import React, { Fragment } from 'react';
import { Button, Media } from 'reactstrap';
import SecondTimer from '../../Components/Components/SecondTimer';
import humanImg from '../../../../../images/redesign/tutorial/hurman.webp';
import coinImg from '../../../../../images/redesign/common/coin.svg';

const SnackMessage = ({wineGiftRemoveFunc, wineGiftTimer, wineGiftSender, wineGiftMessageVisible, setWineGiftTimerDisabledFunc, setWineGiftSenderFunc, setWineGiftMessageVisibleFunc, t}) => {

    const countdown = <SecondTimer finishTimeStamp={wineGiftTimer} onFinished={() => { setWineGiftMessageVisibleFunc(false); setWineGiftSenderFunc(null); setWineGiftTimerDisabledFunc(); }}/>
    
    // if (!wineGiftMessageVisible) return null;
    return (
        <Fragment>
            <Media className="snack-message-img" src={humanImg} style={{visibility: wineGiftMessageVisible ? 'visible': 'collapse'}} />
            <div className="snack-message-textbox" style={{visibility: wineGiftMessageVisible ? 'visible': 'collapse'}}>
                <div className="snack-message-textbox-arrow" />
                <p className="mb-1"><b>{wineGiftSender}</b> {t('notifications:serveDrink')}</p>
                <p className="mb-1">{t('notifications:fogTime')} <b>{countdown}</b> {t('notifications:fogTimeSeconds')}</p>
                <p className="mb-1">{t('notifications:takeSnack')}</p>
                <p className="mb-1">{t('notifications:snackInfo')}</p>
                <div className="snack-message-textbox-coins">
                    <Media className="snack-message-textbox-coins-image" src={coinImg} />
                    100
                </div>
                <Button
                    type="button"
                    color="link"
                    className="snack-message-textbox-snack-button"
                    onClick={() => wineGiftRemoveFunc()}
                >
                    {t('notifications:snack')}
                </Button>
                <Button
                    type="button"
                    color="link"
                    className="snack-message-textbox-hide-button"
                    onClick={() => setWineGiftMessageVisibleFunc(false)}
                >
                    {t('notifications:close')}
                </Button>
            </div>
        </Fragment>
    );
};

export default SnackMessage;