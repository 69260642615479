import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { getRandomNumber } from '../../../utils/numUtils';

const getCardMoveClasses = (number, card) => {
  if (number === 0) {
    return [`${card}-card-drunk-move-up`, `${card}-card-drunk-move-down`]
  }
  else if (number === 1) {
    return [`${card}-card-drunk-move-left`, `${card}-card-drunk-move-right`]
  }
  else if (number === 2) {
    return [`${card}-card-drunk-move-down`, `${card}-card-drunk-move-up`]
  }
  else if (number === 3) {
    return [`${card}-card-drunk-move-right`, `${card}-card-drunk-move-left`]
  }
  else if (number === 4) {
    return [`${card}-card-drunk-move-up-2`, `${card}-card-drunk-move-down-2`]
  }
  else if (number === 5) {
    return [`${card}-card-drunk-move-left-2`, `${card}-card-drunk-move-right-2`]
  }
  else if (number === 6) {
    return [`${card}-card-drunk-move-down-2`, `${card}-card-drunk-move-up-2`]
  }
  else if (number === 7) {
    return [`${card}-card-drunk-move-right-2`, `${card}-card-drunk-move-left-2`]
  }
  // if (number === 0) {
  //   return [`drunk-move-up`, `drunk-move-down`]
  // }
  // else if (number === 1) {
  //   return [`drunk-move-left`, `drunk-move-right`]
  // }
  // else if (number === 2) {
  //   return [`drunk-move-down`, `drunk-move-up`]
  // }
  // else if (number === 3) {
  //   return [`drunk-move-right`, `drunk-move-left`]
  // }
  // else if (number === 4) {
  //   return [`drunk-move-up-2`, `drunk-move-down-2`]
  // }
  // else if (number === 5) {
  //   return [`drunk-move-left-2`, `drunk-move-right-2`]
  // }
  // else if (number === 6) {
  //   return [`drunk-move-down-2`, `drunk-move-up-2`]
  // }
  // else if (number === 7) {
  //   return [`drunk-move-right-2`, `drunk-move-left-2`]
  // }
};

// const Render3PlayerCards = React.memo(({
//   currentTable, cardsAdded, myPos, isFullscreen, isWebVersion,
// }) => (
//   <div className="played-cards">
//     {myPos === 'player1' && (
//     <Fragment>
//       {currentTable && Object.keys(currentTable).map((key, index) => (
//         <Fragment key={currentTable[key] ? currentTable[key].card : key}>
//           {(currentTable[key] && currentTable[key].player === 'player1') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player2') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player3') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//         </Fragment>
//       ))}
//     </Fragment>
//     )}
//     {myPos === 'player2' && (
//     <Fragment>
//       {currentTable && Object.keys(currentTable).map((key, index) => (
//         <Fragment key={currentTable[key] ? currentTable[key].card : key}>
//           {(currentTable[key] && currentTable[key].player === 'player1') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player2') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player3') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//         </Fragment>
//       ))}
//     </Fragment>
//     )}
//     {myPos === 'player3' && (
//     <Fragment>
//       {currentTable && Object.keys(currentTable).map((key, index) => (
//         <Fragment key={currentTable[key] ? currentTable[key].card : key}>
//           {(currentTable[key] && currentTable[key].player === 'player1') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player2') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//           {(currentTable[key] && currentTable[key].player === 'player3') ? (
//             <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
//           ) : (null)}
//         </Fragment>
//       ))}
//     </Fragment>
//     )}
//   </div>
// ));


const Render3PlayerCards = React.memo(({
  currentTable, cardsAdded, myPos, currentTurn, tableIsInProgress, gameState, firstToGo, isFullscreen, isWebVersion, isDrunk,
}) => {
  const items = [];

  const [cardMoveClassesMiddle, setCardMoveClassesMiddle] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'middle'));
  // const [cardMoveClassesLeft, setCardMoveClassesLeft] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'left'));
  // const [cardMoveClassesRight, setCardMoveClassesRight] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'right'));

  if (myPos === 'player3') {

    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (isDrunk) {
        if (currentTable[key] && currentTable[key].player === 'player1') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player2') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player3') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
      }
      return null;
    });

    // table card backgrounds
    // if (currentTable && currentTable.length <= 3) {
    //   if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
    //     items.push(<div className="table-card-bg card-0" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
    //     items.push(<div className="table-card-bg card-2" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
    //     items.push(<div className="table-card-bg card-1" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //   }
    // }

    return (
      <div className={`played-cards ${isDrunk && 'pulse-blur'}`}>
        {items}
      </div>
    );
  }

  if (myPos === 'player1') {
    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (isDrunk) {
        if (currentTable[key] && currentTable[key].player === 'player1') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player2') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player3') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
      }
      return null;
    });

    // table card backgrounds
    // if (currentTable && currentTable.length <= 3) {
    //   if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
    //     items.push(<div className="table-card-bg card-1" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
    //     items.push(<div className="table-card-bg card-0" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
    //     items.push(<div className="table-card-bg card-2" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //   }
    // }

    return (
      <div className={`played-cards ${isDrunk && 'pulse-blur'}`}>
        {items}
      </div>
    );
  }

  if (myPos === 'player2') {
    // table cards
    Object.keys(currentTable).map((key, index) => {
      if (currentTable[key] && currentTable[key].player === 'player1') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player2') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (currentTable[key] && currentTable[key].player === 'player3') {
        items.push(<div id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />);
      }
      if (isDrunk) {
        if (currentTable[key] && currentTable[key].player === 'player1') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player2') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
        if (currentTable[key] && currentTable[key].player === 'player3') {
          items.push(<div id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} style={{opacity: 0.5}} />);
        }
      }
      return null;
    });

    // table card backgrounds
    // if (currentTable && currentTable.length <= 3) {
    //   if ((gameState !== 'burry' && currentTurn === 'player1') || (gameState === 'burry' && firstToGo === 'player1')) {
    //     items.push(<div className="table-card-bg card-2" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player2') || (gameState === 'burry' && firstToGo === 'player2')) {
    //     items.push(<div className="table-card-bg card-1" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-0" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //   } else if ((gameState !== 'burry' && currentTurn === 'player3') || (gameState === 'burry' && firstToGo === 'player3')) {
    //     items.push(<div className="table-card-bg card-0" />);
    //     if (currentTable.length < 2) {
    //       items.push(<div className="table-card table-card-bg-after card-2" />);
    //     }
    //     if (currentTable.length < 1) {
    //       items.push(<div className="table-card table-card-bg-after card-1" />);
    //     }
    //   }
    // }

    return (
      <div className={`played-cards ${isDrunk && 'pulse-blur'}`}>
        {items}
      </div>
    );
  }

  return null;
});

Render3PlayerCards.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  isFullscreen: PropTypes.bool,
  isWebVersion: PropTypes.bool,
};

Render3PlayerCards.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  isFullscreen: false,
  isWebVersion: false,
};

export default Render3PlayerCards;
