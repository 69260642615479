import React, { useEffect, useState } from 'react';

const SecondTimer = ({finishTimeStamp, innerClassName, onFinished }) => {
    const [secondsRemaining, setSecondsRemaining] = useState(0);

    useEffect(() => {
        let intervalId;

        if (finishTimeStamp) {
            intervalId = setInterval(() => {
                const seconds = Math.round((finishTimeStamp - Date.now()) / 1000);
                
                setSecondsRemaining(seconds);
                if (seconds <= 0) {
                    clearInterval(intervalId);
                    if (onFinished) {
                        onFinished();
                    }
                }
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [finishTimeStamp]);

    return (
        <span className={`second-timer ${innerClassName}`} style={{/*position: 'fixed', top: 55, left: '50vw', color: 'white'*/}}>
            {secondsRemaining}
        </span>
    );
};

export default SecondTimer;
