import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
// import classNames from 'classnames';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
// import Input from 'reactstrap/lib/Input';

// import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import CustomModal from '../../../Components/Components/Modal';
// import Toggle from '../../../Components/Components/Toggle';

import Gift from './Gift';

// import defaultImage from '../../../../../../images/Game/defaultImage.webp';
import coinImg from '../../../../../../images/redesign/common/coin.svg';
import { GIFTS_ITEMS, GIFTS_TAB } from '../../../../../../constants/constants';

class SendGift extends Component {
  static propTypes = {
    sendGift: PropTypes.func.isRequired,
    playButtonSound: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    gifts: PropTypes.shape(),
    players: PropTypes.shape(),
    notificationSettings: PropTypes.shape(),
    modalOpen: PropTypes.bool,
  //  isFullscreen: PropTypes.bool,
    fourPRoom: PropTypes.bool,
    roomId: PropTypes.string,
    initialSelected: PropTypes.string,
    popularGifts: PropTypes.shape({}),
  }

  static defaultProps = {
    gifts: {},
    players: {},
    notificationSettings: {},
    modalOpen: false,
  //  isFullscreen: false,
    fourPRoom: false,
    roomId: null,
    initialSelected: null,
    popularGifts: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      giftId: '',
      comment: '',
      playerChecked: {
        player0: false,
        player1: false,
        player2: false,
        player3: false,
      },
      allChecked: false,
      playerKeys: ['player1', 'player2', 'player3', 'player4'],
      sendingGift: false,
      giftSent: false,
      activeItem: 'popular',
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    const { initialSelected, players } = nextProps;

    if (players && initialSelected) {
      let selected;
      if (players.player1 && initialSelected === players.player1.uid) {
        selected = 'player0';
      } else if (players.player2 && initialSelected === players.player2.uid) {
        selected = 'player1';
      } else if (players.player3 && initialSelected === players.player3.uid) {
        selected = 'player2';
      } else if (players.player4 && initialSelected === players.player4.uid) {
        selected = 'player3';
      }

      if (selected) {
        this.setState(prevState => ({
          playerChecked: {
            ...prevState.playerChecked,
            [selected]: true,
          },
        }));
      }
    }
  }

  handleChange = (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const { name } = event.target;

    console.log('handleChange', { value, name });

    const { fourPRoom } = this.props;

    if (name === 'allChecked') {
      const { playButtonSound, players, uid } = this.props;
      const {playerChecked, allChecked} = this.state;
      playButtonSound();

      if (fourPRoom) {
        this.setState({
          playerChecked: {
            player0: players.player1.uid !== uid ? value : false,
            player1: players.player2.uid !== uid ? value : false,
            player2: players.player3.uid !== uid ? value : false,
            player3: fourPRoom ? (players.player4.uid !== uid ? value : false) : false,
          },
          allChecked: value,
        });
      } else {
        console.log({playerChecked}, {allChecked}, {value})
        this.setState({
          playerChecked: {
            player0: value,
            player1: value,
            player2: value,
          },
          allChecked: value,
        });
      }
    } else if (name === 'player0' || name === 'player1' || name === 'player2' || name === 'player3') {
      const { playButtonSound } = this.props;
      const { playerChecked } = this.state;
      playButtonSound();

      let {
        player0, player1, player2, player3,
      } = playerChecked;
      if (name === 'player0') {
        player0 = value;
      } else if (name === 'player1') {
        player1 = value;
      } else if (name === 'player2') {
        player2 = value;
      } else if (name === 'player3') {
        player3 = value;
      }

      this.setState(prevState => ({
        playerChecked: {
          ...prevState.playerChecked,
          [name]: value,
        },
        allChecked: (!!(!fourPRoom && player0 && player1 && player2) || !!(fourPRoom && player0 && player1 && player2 && player3)),
      }));
    } else if (name === 'comment') {
      const striped = value.replace(/[^A-Za-z0-9\s!?\u0020-\u0080\u0400-\u04FF\u0080-\u024F]/g, '');

      const trimmed = striped.substring(0, 100);

      this.setState({
        [name]: trimmed,
      });
    }
  }

  sendGift = (giftId, drink) => {
    const {
      sendGift, players, roomId, playButtonSound,
    } = this.props;
    const {
      playerChecked, comment, sendingGift,
    } = this.state;

    const {
      player0, player1, player2, player3,
    } = playerChecked;

    const selectedPlayerIds = [];
    if (player0 && players.player1) {
      selectedPlayerIds.push(players.player1.uid);
    }
    if (player1 && players.player2) {
      selectedPlayerIds.push(players.player2.uid);
    }
    if (player2 && players.player3) {
      selectedPlayerIds.push(players.player3.uid);
    }
    if (player3 && players.player4) {
      selectedPlayerIds.push(players.player4.uid);
    }

    this.setState({ confirmationModal: false });


    if (!sendingGift && selectedPlayerIds.length > 0 && giftId) {
      playButtonSound();

      this.setState({ sendingGift: true });

      sendGift(roomId, giftId, comment, selectedPlayerIds, drink).then((res) => {
        if (res === 'success') {
          this.setState({
            giftSent: true,
          });
          this.closeModal();
        }
        this.setState({ sendingGift: false });
      }).catch(() => {
        this.setState({ sendingGift: false });
      });
    }
  }

  closeModal = () => {
    const { toggle, playButtonSound } = this.props;

    playButtonSound();

    this.setState({
      giftId: '',
      comment: '',
      playerChecked: {
        player0: false,
        player1: false,
        player2: false,
        player3: false,
      },
      allChecked: false,
      giftSent: false,
      confirmationModal: false,
  });

    toggle(null, false);
  }

  // selectGift = (key) => {
  //   const { playButtonSound } = this.props;
  //   playButtonSound();

  //   this.setState({ giftId: key });
  // }

  startSendingGift = (giftId, drink) => {
    const { notificationSettings, playButtonSound } = this.props;
    playButtonSound();

    if (notificationSettings && notificationSettings.purchaseGift === false) {
      this.sendGift(giftId, drink);
    } else {
      this.setState({ giftId, drink });
      this.toggleConfirmModal(giftId, drink);
    }
  }

  toggleConfirmModal = (key, drink) => {
    const { gifts, drinkGifts } = this.props;
    const { playerChecked } = this.state;
    const gift = drink ? drinkGifts[key] : gifts[key];

    if (gift) {
      const { price } = gift;

      let totalPrice = 0;
      let selectedusersCount = 0;

      Object.keys(playerChecked).map((key) => {
        if (playerChecked[key]) {
          totalPrice += price;
          selectedusersCount += 1;
        }
        return null;
      });

      this.setState({
        giftNotSelected: false,
        totalPrice,
        selectedusersCount,
        confirmationModal: true,
      });
    } else {
      this.setState(prevState => ({
        giftId: '',
        giftNotSelected: true,
        confirmationModal: !prevState.confirmationModal,
      }));
    }
  }

  toggleActiveItem = (gift) => {
    const { activeItem } = this.state;

    if (gift === activeItem) return;

    this.setState({
      activeItem: gift
    })
  }

  render() {
    const {
      gifts,
      drinkGifts,
      t,
      modalOpen,
      players,
    //  isFullscreen,
      popularGifts,
    } = this.props;

    const {
      playerChecked,
      allChecked,
      giftId,
      comment,
      confirmationModal,
      totalPrice,
      selectedusersCount,
      giftNotSelected,
      playerKeys,
      giftSent,
      drink,
      activeItem
    } = this.state;
    return (
      <div>
        <CustomModal
          addClassName="send-gifts-section"
          isOpen={modalOpen}
          toggle={this.closeModal}
          size="lg"
          width={650}
          title={t('gifts.gifts')}
          modalVerticalCentering
          body={(
            <div
              className="send-gifts-scrollarea"
              contentClassName="send-gifts-scrollarea-body"
              show
              rightOffset={16}
              topOffset={54}
              bottomOffset={-3}
            >
              <div className="send-gifts">
                <Row>
                  <Col className="text-center mb-2">
                    {t('gifts.buyAnyGift')}
                    <Media style={{display: 'inline-block'}} src={coinImg}/>
                  </Col>
                </Row>
                <Row>
                  <div className='d-flex flex-row no-wrap w-full justify-between px-3 gap-2'>
                    {GIFTS_TAB.map((item, idx) => (
                      <div key={idx} className={`d-flex align-items-center px-3 py-2 gift-tab-button ${activeItem === item && 'gift-tab-button-active'}`} onClick={() => this.toggleActiveItem(item)}>
                        <span className='w-full break-word'>{t(`gifts.${item}`)}</span>
                      </div>
                    ))}
                  </div>
                </Row>
                <Row>
                  <Col xs="12">
                    {/* {gifts && Object.keys(gifts).map(key => (
                      <Fragment key={key}>
                        <Gift
                          altClassName="gifts-gift-alt"
                          id={key}
                        //  giftId={giftId}
                          isSelected={giftId === key}
                          gift={gifts[key]}
                          selectGift={() => this.startSendingGift(key)}
                        //  isFullscreen={isFullscreen}
                        />
                      </Fragment>
                    ))} */}

                    {activeItem === 'popular' ? (
                      <div style={{ maxWidth: '100%', width: '100%', padding: '15px' }}>
                        {popularGifts && Object.keys(popularGifts).map(key => (
                          <Fragment key={key}>
                            <Gift
                              id={key}
                              altClassName="gifts-gift-alt"
                              isSelected={giftId === popularGifts[key].name}
                              gift={popularGifts[key]}
                              selectGift={() => this.startSendingGift(key)}
                            />
                          </Fragment>
                        ))}
                      </div>
                    ) : (
                      <div style={{ maxWidth: '100%', width: '100%', padding: '15px' }}>

                        {GIFTS_ITEMS[activeItem] && GIFTS_ITEMS[activeItem].map((item, key) => {
                          if (!gifts[item]) return;

                          return (
                          <Fragment key={key}>
                            <Gift
                              altClassName="gifts-gift-alt"
                              id={gifts[item].name}
                              isSelected={giftId === gifts[item].name}
                              gift={gifts[item]}
                              selectGift={() => this.startSendingGift(gifts[item].name)}
                            />
                          </Fragment>
                        )})}
                        {activeItem === 'foodAndDrinks' && drinkGifts && Object.keys(drinkGifts).map(key => (
                          <Fragment key={`drinks-${key}`}>
                            <Gift
                              altClassName="gifts-gift-alt"
                              id={key}
                            //  giftId={giftId}
                              isSelected={giftId === key}
                              gift={drinkGifts[key]}
                              selectGift={() => this.startSendingGift(key, true)}
                              isDrinkGift={true}
                            //  isFullscreen={isFullscreen}
                            />
                          </Fragment>
                        ))}
                      </div>
                    )}

                  </Col>
                </Row>
              </div>
            </div>
          )}
        />

        <CustomModal
          isOpen={confirmationModal}
          toggle={this.toggleConfirmModal}
          size="md"
          verticalCentering
          largeText
          title={ !giftSent ? t('gifts.gifts') : t('gifts.giftSent')}
          footer={(
            <>
              {
                !giftSent ? (
                  giftNotSelected ? (
                    <Button className="modal-footer-button" onClick={this.toggleConfirmModal}>
                      {t('home.close')}
                    </Button>
                  ) : (
                    <Fragment>
                      {selectedusersCount === 0 ? (
                        <Button className="modal-footer-button" onClick={this.toggleConfirmModal}>
                          {t('home.close')}
                        </Button>
                      ) : (
                        <Fragment>
                          <Button className="modal-footer-button" onClick={() => this.sendGift(giftId, drink)}>
                            {t('common.yes')}
                          </Button>
                          <Button className="modal-footer-button" onClick={this.toggleConfirmModal}>
                            {t('common.no')}
                          </Button>
                        </Fragment>
                      )}
                    </Fragment>
                  )
                ) : (
                  <Button className="modal-footer-button" onClick={this.toggleConfirmModal}>
                    {t('common.ok')}
                  </Button>
                )
              }
            </>
          )}
          body={(
            giftSent ? (
              <div className="send-gifts-confirmation">
                {t('gifts.sendGiftConfirm')}
              </div>
            ) : (
              <div className="send-gifts-confirmation">
                {
                  giftNotSelected ? (
                    `${t('gifts.pick')}`
                  ) : selectedusersCount === 0 ? (
                    `${t('gifts.noUser')}`
                  ) : selectedusersCount === 1 ? (
                    `${t('gifts.confirmation')} ${totalPrice} ${t('gifts.coins')}­ `
                  ) : selectedusersCount > 1 ? (
                    `${t('gifts.multipleGiftConfirmation')} ${totalPrice} ${t('gifts.coins')}­ `
                  ) : (null)
                }
              </div>
            )
          )}
        />
      </div>
    );
  }
}


const mapStateToProps = state => ({
  gifts: state.rooms.gifts || {},
  drinkGifts: state.rooms.drinkGifts || {},
  popularGifts: state.rooms.popularGifts || {},
  players: state.game.players,
  fourPRoom: state.game.globalParams.fourPRoom,
});

// const mapDispatchToProps = {

// };

export default connect(mapStateToProps, null)(withTranslation('common')(SendGift));
