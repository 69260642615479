import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import { DropdownItem } from 'reactstrap';
// import DropdownItem from 'reactstrap/lib/DropdownItem';

const CustomDropdown = React.memo(({
  toggle, menu, isOpen, toggleFunc, buttonType, inlineMenuClassName, innerAutoFocus,
}) => (
  <>
    <Dropdown className="custom-dropdown" isOpen={isOpen} toggle={toggleFunc} autoFocus={innerAutoFocus}>
      <DropdownToggle className={`${buttonType === 'link' ? 'custom-dropdown-link' : 'custom-dropdown-toggle'}`} caret={buttonType !== 'link'}>
        {toggle}
      </DropdownToggle>
      <DropdownMenu className={`custom-dropdown-menu ${inlineMenuClassName}`}>
        {menu}
      </DropdownMenu>
    </Dropdown>
  </>
));

CustomDropdown.propTypes = {
  isOpen: PropTypes.bool,
  toggleFunc: PropTypes.func.isRequired,
  text: PropTypes.string,
  buttonType: PropTypes.string,
  inlineMenuClassName: PropTypes.string,
  innerAutoFocus: PropTypes.bool,
};

CustomDropdown.defaultProps = {
  isOpen: false,
  text: null,
  buttonType: null,
  inlineMenuClassName: '',
  innerAutoFocus: true,
};

export default CustomDropdown;
