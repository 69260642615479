import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Lottie from 'react-lottie';

import Media from 'reactstrap/lib/Media';

import closeImg from '../../../images/icons/close.png';
import pro from '../../../images/icons/pro.svg';
import fast from '../../../images/icons/fast_game.svg';
import lightning from '../../../images/icons/fast_game_lightning.svg';
import unlimited from '../../../images/redesign/common/Room types unlimited.svg';

import minGamesImg from '../../../images/icons/min_games.svg';

import pupoliImg from '../../../images/Game/pupoli.svg';
import alusAnimImg from '../../../images/Holiday/alus-kauss.json';
import { isTodayEaster } from '../../../utils/dateUtils';
// import alusKaussImg from '../../../images/Holiday/alus-kauss.png';

const StaticElements = React.memo(({
  fastGame, lightningGame, unlimitedGame, proGame, exitRoomNotification, minGames, speed, selectedTestHoliday, holidayTest,// fourPRoom,
}) => {
  const x = new Date();

  const date = x.getDate();
  const month = x.getMonth();
  return (
    <Fragment>
      <div className="top-left">
        <div className="top-left-exit">
          <Media className="exit-button" src={closeImg} alt="X" onClick={exitRoomNotification} />
        </div>
        {fastGame || speed === 15 ? (
          <div className="top-left-fast">
            <Media className="label-speed" src={fast} alt="Ātrā" />
          </div>
        ) : (null)}
        {lightningGame || speed === 6 ? (
          <div className="top-left-fast">
            <Media className="label-speed" src={lightning} alt="Ātrā" />
          </div>
        ) : (null)}
        {unlimitedGame || speed === 60 ? (
          <div className="top-left-fast">
            <Media className="label-speed" src={unlimited} alt="Ātrā" />
          </div>
        ) : (null)}
        {proGame ? (
          <div className="top-left-pro">
            <Media className="label-speed" src={pro} alt="Pro" />
          </div>
        ) : (null)}
        {minGames ? (
          <>
            <div className="top-left-minGames">
              <Media className="top-left-minGames-img" src={minGamesImg} alt="4 Spēlētāji" />
              <p className="top-left-minGames-text">
                {minGames || (
                  '1'
                )}
              </p>
            </div>
            {/*  <div className="top-left-minGames">
            <div className="gameType">
              <p className="gameType-text">
                {minGames}
              </p>
            </div>
          </div>  */}
          </>
        ) : (null)}
        {/*  {fourPRoom && (
          <Fragment>
            <Media
              className="label-private"
              src={fourPImg}
              alt="4 Spēlētāji"
            />
          </Fragment>
        )}  */}
      </div>

      {(isTodayEaster()) || (holidayTest && (selectedTestHoliday[0].id === 'easter')) ? (
        <Media className="pupoli-image" src={pupoliImg} alt="" />
      ) : (null)}

      {((date && month && (date === 23 || date === 24) && month === 5) || (holidayTest && (selectedTestHoliday[0].id === 'ligo' || selectedTestHoliday[0].id === 'jani'))) ? (
        <Fragment>
          <div className="alus-image">
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: alusAnimImg,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={152}
              width={117}
              isStopped={false}
              isPaused={false}
            />
          </div>
          {/*  <Media className="alus-kauss-image" src={alusKaussImg} alt="" /> */}
        </Fragment>
      ) : (null)}
    </Fragment>
  );
});

StaticElements.propTypes = {
  fastGame: PropTypes.bool,
  lightningGame: PropTypes.bool,
  unlimitedGame: PropTypes.bool,
  proGame: PropTypes.bool,
  minGames: PropTypes.number,
  speed: PropTypes.number,
  //  fourPRoom: PropTypes.bool,
  exitRoomNotification: PropTypes.func.isRequired,
};

StaticElements.defaultProps = {
  fastGame: null,
  lightningGame: null,
  unlimitedGame: null,
  proGame: null,
  minGames: null,
  speed: null,
//  fourPRoom: null,
};

const mapStateToProps = state => ({
  fastGame: state.game.globalParams.fastGame,
  lightningGame: state.game.globalParams.lightningGame,
  unlimitedGame: state.game.globalParams.unlimitedGame,
  proGame: state.game.globalParams.proGame,
  minGames: state.game.globalParams.minGames,
  speed: state.game.globalParams.speed,
//  fourPRoom: state.game.globalParams.fourPRoom,
});

// const mapDispatchToProps = {
//  getOffset: getTimeOffset,
// };

export default connect(mapStateToProps, null)(StaticElements);
