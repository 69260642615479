import React from 'react';
import PropTypes from 'prop-types';

/* import {
  Col,
  Media,
  Button,
} from 'reactstrap'; */

import Media from 'reactstrap/lib/Media';
// import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';

import coinImg from '../../../images/coin.svg';

class Gift extends React.Component {
  static propTypes = {
    //  t: PropTypes.func.isRequired,
    giftId: PropTypes.string,
    id: PropTypes.string,
    gift: PropTypes.shape(),
    isFullscreen: PropTypes.bool,
    selectGift: PropTypes.func.isRequired,
    altClassName: PropTypes.string,
    isDrinkGift: PropTypes.bool,
  };

  static defaultProps = {
    giftId: null,
    id: null,
    gift: {},
    isFullscreen: false,
    altClassName: null,
    isDrinkGift: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    const {
      giftId,
      id,
      //  gift,
      isFullscreen,
      drink,
      isDrinkGift,
    } = nextProps;
    const {
      giftId: curGiftId,
      id: curId,
      isFullscreen: curIsFullscreen,
      //  gift: curGift,
      drink: curDrink,
      isDrinkGift: curIsDrinkGift
    } = this.props;

    if (giftId !== curGiftId && giftId === curId) {
      return true;
    }

    if (giftId !== curGiftId && curGiftId === curId) {
      return true;
    }

    if (id !== curId) {
      return true;
    }

    if (isFullscreen !== curIsFullscreen) {
      return true;
    }
    if (drink !== curDrink) {
      return true;
    }

    if (isDrinkGift !== curIsDrinkGift) {
      return true;
    }
    //  if (gift !== curGift) {
    //    return true;
    //  }

    return false;
  }

  render() {
    const {
      //  t,
      giftId,
      drink,
      id,
      gift,
      selectGift,
      isFullscreen,
      curGiftId,
      altClassName,
      isDrinkGift,
    } = this.props;

    return (
      <div
        key={id}
        className={`gifts-gift ${isFullscreen ? 'gifts-gift-fullscreen' : 'gifts-gift-selected'} ${giftId === id && !!drink === isDrinkGift ? 'gifts-gift-selected' : ''} ${altClassName ? altClassName : ''}`}
        onClick={() => selectGift(id)}
        style={{ cursor: 'pointer' }}
      >
        <Media src={gift.image} className="gifts-gift-image" />
        { isDrinkGift &&
          <div className="gifts-gift-pricebox">
            <Media src={coinImg} />
            {gift.price}
          </div>
        }
      </div>
    );
  }
}

export default Gift;
