import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { getRandomNumber } from '../../../utils/numUtils';

const getCardMoveClasses = (number, card) => {
  if (number === 0) {
    return [`${card}-card-drunk-move-up`, `${card}-card-drunk-move-down`]
  }
  else if (number === 1) {
    return [`${card}-card-drunk-move-left`, `${card}-card-drunk-move-right`]
  }
  else if (number === 2) {
    return [`${card}-card-drunk-move-down`, `${card}-card-drunk-move-up`]
  }
  else if (number === 3) {
    return [`${card}-card-drunk-move-right`, `${card}-card-drunk-move-left`]
  }
  else if (number === 4) {
    return [`${card}-card-drunk-move-up-2`, `${card}-card-drunk-move-down-2`]
  }
  else if (number === 5) {
    return [`${card}-card-drunk-move-left-2`, `${card}-card-drunk-move-right-2`]
  }
  else if (number === 6) {
    return [`${card}-card-drunk-move-down-2`, `${card}-card-drunk-move-up-2`]
  }
  else if (number === 7) {
    return [`${card}-card-drunk-move-right-2`, `${card}-card-drunk-move-left-2`]
  }
};

const Render4PlayerCards = React.memo(({
  currentTable, cardsAdded, myPos, sittingOut, isFullscreen, isWebVersion, isDrunk,
}) => {
  const [cardMoveClassesMiddle, setCardMoveClassesMiddle] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'middle'));
  // const [cardMoveClassesLeft, setCardMoveClassesLeft] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'left'));
  // const [cardMoveClassesRight, setCardMoveClassesRight] = useState(getCardMoveClasses(getRandomNumber(0, 7), 'right'));

  return (
    <div className={`played-cards ${isDrunk && 'pulse-blur'} ${sittingOut === myPos ? ('played-cards-sittingOut') : null}`}>
      {sittingOut === 'player1' && (
        <Fragment>
          {myPos === 'player1' && (//
          <Fragment>
            {currentTable && Object.keys(currentTable).map((key, index) => (
              <Fragment key={currentTable[key] ? currentTable[key].card : key}>
                {(currentTable[key] && currentTable[key].player === 'player2') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player3') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player4') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
              </Fragment>
            ))}
            {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
              <Fragment key={currentTable[key] ? currentTable[key].card : key}>
                {(currentTable[key] && currentTable[key].player === 'player2') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player3') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player4') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
              </Fragment>
            ))}
          </Fragment>
          )}
          {myPos === 'player2' && (//${cardMoveClassesMiddle[1]}
          <Fragment>
            {currentTable && Object.keys(currentTable).map((key, index) => (
              <Fragment key={currentTable[key] ? currentTable[key].card : key}>
                {(currentTable[key] && currentTable[key].player === 'player2') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player3') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player4') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
              </Fragment>
            ))}
            {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
              <Fragment key={currentTable[key] ? currentTable[key].card : key}>
                {(currentTable[key] && currentTable[key].player === 'player2') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player3') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player4') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
              </Fragment>
            ))}
          </Fragment>
          )}
          {myPos === 'player3' && (//${cardMoveClassesMiddle[1]}
          <Fragment>
            {currentTable && Object.keys(currentTable).map((key, index) => (
              <Fragment key={currentTable[key] ? currentTable[key].card : key}>
                {(currentTable[key] && currentTable[key].player === 'player2') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player3') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player4') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
              </Fragment>
            ))}
            {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
              <Fragment key={currentTable[key] ? currentTable[key].card : key}>
                {(currentTable[key] && currentTable[key].player === 'player2') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player3') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player4') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
              </Fragment>
            ))}
          </Fragment>
          )}
          {myPos === 'player4' && (//${cardMoveClassesMiddle[1]}
          <Fragment>
            {currentTable && Object.keys(currentTable).map((key, index) => (
              <Fragment key={currentTable[key] ? currentTable[key].card : key}>
                {(currentTable[key] && currentTable[key].player === 'player2') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player3') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player4') ? (
                  <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
              </Fragment>
            ))}
            {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
              <Fragment key={currentTable[key] ? currentTable[key].card : key}>
                {(currentTable[key] && currentTable[key].player === 'player2') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player3') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
                {(currentTable[key] && currentTable[key].player === 'player4') ? (
                  <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
                ) : (null)}
              </Fragment>
            ))}
          </Fragment>
          )}
        </Fragment>
      )}

      {sittingOut === 'player2' && (
      <Fragment>
        {myPos === 'player1' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player2' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player3' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player4' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && isDrunk && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && isDrunk && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && isDrunk && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
      </Fragment>
      )}

      {sittingOut === 'player3' && (
      <Fragment>
        {myPos === 'player1' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player2' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player3' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player4' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player4') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
      </Fragment>
      )}


      {sittingOut === 'player4' && (
      <Fragment>
        {myPos === 'player1' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player2' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player3' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
        {myPos === 'player4' && (//
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
          {currentTable && isDrunk && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key] ? currentTable[key].card : key}>
              {(currentTable[key] && currentTable[key].player === 'player1') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player2') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
              {(currentTable[key] && currentTable[key].player === 'player3') ? (
                <div style={{ opacity: 0.5, visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`${cardMoveClassesMiddle[1]} table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              ) : (null)}
            </Fragment>
          ))}
        </Fragment>
        )}
      </Fragment>
      )}

      {/* Change for fourPRoom update  */}
      {/*  {myPos === 'player1' && (
        <Fragment>
          {currentTable && Object.keys(currentTable).map((key, index) => (
            <Fragment key={currentTable[key].card}>
              {currentTable[key].player === 'player1' && (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              )}
              {currentTable[key].player === 'player2' && (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              )}
              {currentTable[key].player === 'player3' && (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              )}
              {currentTable[key].player === 'player4' && (
              <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
              )}
            </Fragment>
          ))}
        </Fragment>
      )}
      {myPos === 'player2' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key].card}>
            {currentTable[key].player === 'player1' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player2' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player3' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player4' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player3' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key].card}>
            {currentTable[key].player === 'player1' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player2' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player3' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player4' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
          </Fragment>
        ))}
      </Fragment>
      )}
      {myPos === 'player4' && (
      <Fragment>
        {currentTable && Object.keys(currentTable).map((key, index) => (
          <Fragment key={currentTable[key].card}>
            {currentTable[key].player === 'player1' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-0 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player2' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-3 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player3' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-2 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
            {currentTable[key].player === 'player4' && (
            <div style={{ visibility: cardsAdded[currentTable[key].card] ? 'hidden' : 'visible' }} key={currentTable[key].card} id={`table-card-${currentTable[key].card}`} className={`table-card card card-1 card-order-${index} card-${currentTable[key].card} ${(isWebVersion && !isFullscreen) ? 'table-card-web' : ''}`} />
            )}
          </Fragment>
        ))}
      </Fragment>
      )} */}
    </div>
  )
});

Render4PlayerCards.propTypes = {
  currentTable: PropTypes.arrayOf(PropTypes.shape()),
  cardsAdded: PropTypes.shape(),
  myPos: PropTypes.string,
  sittingOut: PropTypes.string,
};

Render4PlayerCards.defaultProps = {
  currentTable: [],
  cardsAdded: {},
  myPos: '',
  sittingOut: null,
};

export default Render4PlayerCards;
